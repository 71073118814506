import React from 'react';
import PropTypes from 'prop-types';
import ToggleButtonGroup from '../ToggleButton/ToggleButtonGroup';
import ToggleButton from '../ToggleButton/ToggleButton';

/**
 * Tristate control implemented with ToggleButtonGroup.
 *
 * @param {Object} props - props for the tristate component
 * @returns {JSX.Element} - The checkbox component
 */
const ToggleButtonTristateGroup = ({
    name,
    className,
    buttonTrueLabel,
    buttonFalseLabel,
    readOnly,
    size,
    groupLabel,
    onChange,
    value,
    mandatory,
}) => {
    const tristateValue = typeof value === 'boolean' ? value : null;

    const handleChange = (event, value) => {
        if (onChange) {
            onChange(name, value);
        }
    };

    return (
        <ToggleButtonGroup
            value={tristateValue}
            onChange={handleChange}
            size={size}
            groupLabel={groupLabel}
            multiselect={false}
            readOnly={readOnly}
            className={className}
            mandatory={mandatory}
        >
            <ToggleButton value={true} key={0}>
                {buttonTrueLabel}
            </ToggleButton>
            <ToggleButton value={false} key={1}>
                {buttonFalseLabel}
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

ToggleButtonTristateGroup.defaultProps = {
    readOnly: false,
    size: 'normal',
    buttonTrueLabel: 'True',
    buttonFalseLabel: 'False',
};

ToggleButtonTristateGroup.propTypes = {
    /** Name of the component.  Required to differentiate two components */
    name: PropTypes.string.isRequired,

    /** CSS class name */
    className: PropTypes.string,

    /** Label for the True button */
    buttonTrueLabel: PropTypes.string,

    /** Label for the False button */
    buttonFalseLabel: PropTypes.string,

    /** True to prohibit any selections */
    readOnly: PropTypes.bool,

    /**
     * Size of buttons.
     */
    size: PropTypes.oneOf(['small', 'normal', 'large']),

    /** Label for entire group */
    groupLabel: PropTypes.string,

    /** Callback function when the value changes. Signature: function(name: string, value: 	bool) => void */
    onChange: PropTypes.func,

    /** The currently selected value within the group */
    value: PropTypes.bool,

    /** Checks if mandatory */
    mandatory: PropTypes.bool,
};

export default ToggleButtonTristateGroup;
