/**
 * @module webcore-ux/react/components/Form
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import memoize from 'memoize-one';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { ConfigType, PageType } from './Constants';
import { renderDisplayField } from './DisplayField';
import cloneDeep from 'lodash.clonedeep';
import Plus from '../Icons/Plus';
import Trash from '../Icons/Trash';
import Edit from '../Icons/Edit';
import { default as GeoLocationIcon } from '../Icons/GeoLocation';
import { convertToReadOnlyTableColumnDefinitions } from './FormUtil';
import styled from 'styled-components';

// Next gen
import { Input, TabContainer, Tab, Checkbox, DayRangePicker, GeoLocation, ToggleButtonTristateGroup, RelativeDatePicker } from '../';
import FormDropdown from './FormDropdown';
import formStyles from './styles';
import ReadOnlyField from './ReadOnlyField';
import TextDisplay from './DisplayFields/TextDisplay';
import { menuPlacementValues, menuPositionValues } from '../../components/Dropdown/Dropdown';

// Old gen
import { ImageGrid, ExpansionPanel, ToggleSwitch, ReadOnlyTable, AttachmentControl } from '../../../react/components';
import LoadingIndicator from '../../../react/components/LoadingIndicator/LoadingIndicator';
import { Typography } from '@material-ui/core';

/**
 * Component for creating dynamic forms from config
 */
export class Form extends React.Component {
    constructor(props) {
        super(props);

        this.getStringResource = this.getStringResource.bind(this);
        // used to check mandatory error fields outside this component
        this.getError = this.getError.bind(this);
        this.validateAllIfChanged = memoize((record, validation) => this.validateAll(record, validation));
        const defaultExpansionPanelState = this.getDefaultExpansionPanelState();

        this.state = {
            prevRecord: null,
            hasChanges: false,
            showErrors: false,
            selectedTab: 0,
            expansionPanelsExpanded: defaultExpansionPanelState,
        };

        this.mandatoryErrors = {};
        this.validationErrors = {};

        this.fieldsWithPattern = [];
        this.fieldsWithMinLength = [];
    }

    static getDerivedStateFromProps(props, state) {
        if (props.record !== state.prevRecord) {
            return {
                prevRecord: props.record,
            };
        }

        return null;
    }

    render() {
        const { className, config, record, validation } = this.props;

        // This should only run during the initial render and if the record or validation prop changes
        this.validateAllIfChanged(record, validation);

        if (!config || config.type !== ConfigType.FORM || !Array.isArray(config.children) || config.children.length < 1) {
            throw new Error('Invalid form config');
        }

        let pageType = PageType.SINGLE;

        if (config.pageType) {
            pageType = config.pageType;
        }

        let page;

        switch (pageType) {
            case PageType.SINGLE:
                page = this.renderPage(config.children[0]);
                break;

            case PageType.TABS:
                page = this.renderTabs(config);
                break;

            case PageType.CAROUSEL:
                throw new Error(`Page type ${pageType} not supported yet`);

            default:
                throw new Error(`Invalid page type ${pageType}`);
        }

        return <div className={classNames('wcux-nxt-form', className)}>{page}</div>;
    }

    /**
     * Render a form page
     *
     * @param {object} config - page config
     * @returns {React.Component|HTMLDivElement} page
     */
    renderPage(config) {
        if (config.type !== ConfigType.PAGE || !Array.isArray(config.children)) {
            throw new Error('Invalid page configuration');
        }

        let title = null;

        if (config.title) {
            title = (
                <Typography className="wcux-nxt-page-title" variant="h3">
                    {this.getStringResource(config.title)}
                </Typography>
            );
        }

        return (
            <div className="wcux-nxt-page">
                {title}
                {this.renderExpansionPanelsToggler(config)}
                {this.renderPageGrid(config)}
            </div>
        );
    }

    /**
     * Renders a form with tabs at the top.
     *
     * @param {object} config - the Config object for the Tabs
     * @returns {React.Component|HTMLDivElement} page
     */
    renderTabs(config) {
        return (
            <div className="wcux-nxt-page">
                <TabContainer
                    value={this.state.selectedTab}
                    className={'wcux-nxt-page-tab-container'}
                    variant={config.tabVariant}
                    onChange={(tab, index) => {
                        this.setState({ selectedTab: index });
                    }}
                >
                    {config.children.map((item, index) => {
                        return (
                            <Tab
                                key={index}
                                className={classNames(`wcux-nxt-page-tab-${index}`)}
                                value={index}
                                label={this.getStringResource(item.title)}
                            />
                        );
                    })}
                </TabContainer>
                {this.state.selectedTab !== false && this.renderExpansionPanelsToggler(config.children[this.state.selectedTab])}
                {this.state.selectedTab !== false && (
                    <div className="wcux-nxt-page-tab-content">{this.renderPageGrid(config.children[this.state.selectedTab])}</div>
                )}
            </div>
        );
    }

    /**
     * Renders Grid that contains sections, used when rendering page.
     *
     * @param {object} config - the Config object for the Page
     * @returns {React.Component|HTMLDivElement} page
     */
    renderPageGrid(config) {
        const defaultSpacing = 3; // 24px
        return (
            <Grid container spacing={config.spacing || defaultSpacing} data-testid="wcux-form-page-grid">
                {this.renderSections(config)}
            </Grid>
        );
    }

    /**
     * Renders a collection of sections
     *
     * @param {object} config - form config containing children sections
     * @returns {React.Component[]} - Array of sections
     */
    renderSections(config) {
        let sections = [];

        config.children.forEach((sectionConfig, index) => {
            let section = this.renderSection(sectionConfig, index);
            sections.push(section);
        }, this);

        return sections;
    }

    /**
     * Render a form section
     *
     * @param {object} config - section config
     * @param {number} key - index of section
     * @returns {React.Component} section
     */
    renderSection(config, key) {
        const {
            type,
            direction,
            supportedPlatforms,
            groupboxDisplay,
            headerIcon,
            isCollapsible,
            disableCollapse,
            defaultExpanded,
            fieldToShowWhenCollapsed,
            xs,
            sm,
            md,
            lg,
            xl,
        } = config;

        const { expansionPanelsExpanded, selectedTab } = this.state;

        if (type !== ConfigType.SECTION || !Array.isArray(config.children)) {
            throw new Error('Invalid section configuration');
        }

        // Section is rendered in all platforms unless specified otherwise
        if (Array.isArray(supportedPlatforms) && supportedPlatforms.length > 0) {
            let supported = false;

            if (window.cordova) {
                supported = supportedPlatforms.includes('cordova');
            } else {
                supported = supportedPlatforms.includes('web');
            }

            if (!supported) {
                return null;
            }
        }

        let children = [];

        config.children.forEach((childConfig, index) => {
            let child;

            // simply do not render anything if field exists in the hiddenFields
            const isHidden =
                childConfig.hidden || (Array.isArray(this.props.hiddenFields) && this.props.hiddenFields.includes(childConfig.name));

            if (!isHidden) {
                let newChildConfig = cloneDeep(childConfig);

                // enforce validation on pattern (RegExp match)
                if (newChildConfig.pattern) {
                    this.fieldsWithPattern[newChildConfig.name] = new RegExp(newChildConfig.pattern);
                }

                // enforce validation on min length
                if (newChildConfig.minLength) {
                    this.fieldsWithMinLength[newChildConfig.name] = newChildConfig.minLength;
                }

                if (Array.isArray(this.props.mandatoryFields) && this.props.mandatoryFields.includes(newChildConfig.name)) {
                    newChildConfig.mandatory = true;
                }

                if (Array.isArray(this.props.readOnlyFields) && this.props.readOnlyFields.includes(newChildConfig.name)) {
                    newChildConfig.readOnly = true;
                }

                if (this.props.isReadOnly) {
                    const canBeReadOnlyTypeList = [
                        ConfigType.INPUT,
                        ConfigType.NUMBER,
                        ConfigType.DROPDOWN,
                        ConfigType.DATE,
                        ConfigType.TIME,
                        ConfigType.DATETIME,
                        ConfigType.DATERANGE,
                        ConfigType.BOOLEAN,
                        ConfigType.TRISTATE,
                        ConfigType.GEOLOCATION,
                        ConfigType.TOGGLESWITCH,
                    ];

                    if (canBeReadOnlyTypeList.includes(newChildConfig.type)) {
                        child = this.renderFormReadOnlyField(newChildConfig, newChildConfig.name || index);
                        children.push(child);
                        return;
                    }
                }

                switch (newChildConfig.type) {
                    case ConfigType.INPUT:
                        child = this.renderTextInput(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.NUMBER:
                        child = this.renderNumberInput(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.DROPDOWN:
                        child = this.renderDropdown(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.DURATION:
                        child = this.renderDurationInput(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.DATE:
                    case ConfigType.TIME:
                    case ConfigType.DATETIME:
                        child = this.renderDateTime(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.DATERANGE:
                        child = this.renderDateRange(newChildConfig, newChildConfig.name || index);
                        break;
                    case ConfigType.RELATIVEDATE:
                        child = this.renderRelativeDate(newChildConfig, newChildConfig.name || index);
                        break;
                    case ConfigType.IMAGEGRID:
                        child = this.renderImageGrid(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.BOOLEAN:
                        child = this.renderBoolean(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.TRISTATE:
                        child = this.renderTristate(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.FILE_ATTACHMENTS:
                        child = this.renderFileAttachment(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.TEXT_DISPLAY:
                    case ConfigType.NUMBER_DISPLAY:
                    case ConfigType.DATETIME_DISPLAY:
                    case ConfigType.DATE_DISPLAY:
                    case ConfigType.TIME_DISPLAY:
                    case ConfigType.BOOLEAN_DISPLAY:
                    case ConfigType.HYPERLINK_DISPLAY:
                    case ConfigType.LISTITEM_DISPLAY:
                    case ConfigType.PHONENUMBER_DISPLAY:
                    case ConfigType.LITERAL_DISPLAY:
                    case ConfigType.STATUS_DISPLAY:
                    case ConfigType.REFERENCE_DISPLAY:
                    case ConfigType.TABLE_DISPLAY:
                    case ConfigType.DURATION_DISPLAY:
                        child = renderDisplayField({
                            config: newChildConfig,
                            configData: this.props.configData,
                            record: this.props.record,
                            getStringResource: this.getStringResource,
                            callbacks: this.props.callbacks || {},
                            key: newChildConfig.name || index,
                        });

                        break;

                    case ConfigType.GEOLOCATION:
                        child = this.renderGeoLocation(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.TOGGLESWITCH:
                        child = this.renderToggleSwitch(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.TABLE:
                        if (
                            this.props.callbacks &&
                            this.props.callbacks[ConfigType.TABLE] &&
                            this.props.callbacks[ConfigType.TABLE].onAdd &&
                            this.props.callbacks[ConfigType.TABLE].onEdit &&
                            this.props.callbacks[ConfigType.TABLE].onDelete
                        ) {
                            child = this.renderEditableTable(
                                newChildConfig,
                                newChildConfig.name || index,
                                this.props.record,
                                this.props.callbacks[ConfigType.TABLE].onAdd,
                                this.props.callbacks[ConfigType.TABLE].onEdit,
                                this.props.callbacks[ConfigType.TABLE].onDelete
                            );
                        }

                        break;

                    case ConfigType.REFERENCE:
                        if (
                            this.props.callbacks &&
                            this.props.callbacks[ConfigType.REFERENCE] &&
                            this.props.callbacks[ConfigType.REFERENCE].onRender
                        ) {
                            const handleChange = (name, value) => this.handleChange(name, value);

                            if (newChildConfig.name) {
                                const value = this.props.record.getValue(newChildConfig.name);
                                this.setMandatoryError(
                                    ConfigType.REFERENCE,
                                    newChildConfig.mandatory,
                                    newChildConfig.name,
                                    value,
                                    newChildConfig.description,
                                    this.getStringResource(newChildConfig.label)
                                );
                            }

                            const error = this.showError(newChildConfig.name);

                            child = this.props.callbacks[ConfigType.REFERENCE].onRender({
                                key: newChildConfig.name || index,
                                config: newChildConfig,
                                record: this.props.record,
                                handleChange,
                                error,
                            });
                        }

                        break;

                    case ConfigType.LINE:
                        child = <hr key={key} className="wcux-nxt-section-separator" />;
                        break;

                    default:
                        throw new Error(`Control type ${newChildConfig.type} not supported`);
                }

                child && children.push(child);
            } else {
                //Reset mandatory error for fields which are now hidden and previously mandatory.
                this.setMandatoryError(
                    childConfig.type,
                    childConfig.mandatory,
                    childConfig.name,
                    this.props.record.getValue(childConfig.name),
                    childConfig.description
                );
            }
        }, this);

        if (children.length === 0) {
            return null;
        }

        if (isCollapsible) {
            let collapsedFieldInfo;

            // only viewer
            if (fieldToShowWhenCollapsed && fieldToShowWhenCollapsed.type) {
                switch (fieldToShowWhenCollapsed.type) {
                    case ConfigType.TEXT_DISPLAY:
                    case ConfigType.NUMBER_DISPLAY:
                    case ConfigType.DATETIME_DISPLAY:
                    case ConfigType.DATE_DISPLAY:
                    case ConfigType.TIME_DISPLAY:
                    case ConfigType.BOOLEAN_DISPLAY:
                    case ConfigType.HYPERLINK_DISPLAY:
                    case ConfigType.LISTITEM_DISPLAY:
                    case ConfigType.PHONENUMBER_DISPLAY:
                    case ConfigType.LITERAL_DISPLAY:
                    case ConfigType.STATUS_DISPLAY:
                    case ConfigType.REFERENCE_DISPLAY:
                    case ConfigType.DURATION_DISPLAY:
                        collapsedFieldInfo = renderDisplayField({
                            config: fieldToShowWhenCollapsed,
                            configData: this.props.configData,
                            record: this.props.record,
                            getStringResource: this.getStringResource,
                            callbacks: this.props.callbacks || {},
                            key: fieldToShowWhenCollapsed.name,
                        });

                        break;

                    default:
                        collapsedFieldInfo = null;
                }
            }

            return (
                <Grid item key={key} className="wcux-nxt-section" xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <ExpansionPanel
                        defaultExpanded={defaultExpanded}
                        disableCollapse={disableCollapse}
                        summary={
                            expansionPanelsExpanded[selectedTab][key]
                                ? this.getStringResource(config.title)
                                : config.fieldToShowWhenCollapsed
                                ? this.getCollapsedTitle(config.title, collapsedFieldInfo)
                                : this.getStringResource(config.title)
                        }
                        headerIcon={headerIcon}
                        details={
                            <div className="wcux-nxt-expansion-panel-form-grid-wrapper">
                                <Grid container direction={direction} spacing={2}>
                                    {config.isLoading ? (
                                        <Grid item xs={12}>
                                            <LoadingIndicator size="small" />
                                        </Grid>
                                    ) : null}
                                    {config.defaultChildLayout
                                        ? children.map((e, i) => {
                                              return (
                                                  <Grid
                                                      {...config.defaultChildLayout}
                                                      item
                                                      key={`${e.key}${i}`}
                                                      data-testid="wcux-form-grid-item"
                                                  >
                                                      {e}
                                                  </Grid>
                                              );
                                          })
                                        : children}
                                </Grid>
                            </div>
                        }
                        onChange={(event, expanded) => this.handleExpansionPanelChange(config, expanded, key)}
                        expanded={expansionPanelsExpanded[selectedTab][key]}
                        applyDetailedStyles={true}
                    />
                </Grid>
            );
        }

        const getGridContents = () => {
            const contents = (
                <>
                    {config.title ? (
                        <Typography className="wcux-nxt-section-title" variant="h5">
                            {this.getStringResource(config.title)}
                        </Typography>
                    ) : null}
                    <Grid container direction={direction} spacing={config.defaultChildLayout && 2}>
                        {config.isLoading ? (
                            <Grid item xs={12}>
                                <LoadingIndicator size="small" />
                            </Grid>
                        ) : null}
                        {config.defaultChildLayout
                            ? children.map((e, i) => {
                                  return (
                                      <Grid {...config.defaultChildLayout} item key={`${e.key}${i}`} data-testid="wcux-form-grid-item">
                                          {e}
                                      </Grid>
                                  );
                              })
                            : children}
                    </Grid>
                </>
            );

            return groupboxDisplay ? <div className="wcux-nxt-section-groupbox">{contents}</div> : contents;
        };

        return (
            <Grid item key={key} className="wcux-nxt-section" xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                {getGridContents()}
            </Grid>
        );
    }

    /**
     * Render a form readOnly control
     *
     * @param {object} config - field config
     * @param {string} key - key
     * @returns {JSX} field control
     */
    renderFormReadOnlyField(config, key) {
        const { label, groupLabel, trueLabel, falseLabel, id, multiline, name, type, checked, startName, endName, startLabel, endLabel } =
            config;

        let value;
        const translatedLabel = this.getStringResource(label);
        if (name) {
            value = this.props.record.getValue(name);
        }

        switch (type) {
            case 'datetime': {
                const date = moment(value);
                if (date.isValid()) {
                    value = date.toDate().toLocaleString();
                }

                return (
                    <ReadOnlyField key={key} label={translatedLabel}>
                        {value}
                    </ReadOnlyField>
                );
            }

            case 'date': {
                const date = moment(value);
                if (date.isValid()) {
                    value = date.toDate().toLocaleDateString();
                }

                return (
                    <ReadOnlyField key={key} label={translatedLabel}>
                        {value}
                    </ReadOnlyField>
                );
            }

            case 'time':
                value = moment(value, [moment.ISO_8601, moment.HTML5_FMT.TIME_SECONDS]).toDate().toLocaleTimeString();
                return (
                    <ReadOnlyField key={key} label={translatedLabel}>
                        {value}
                    </ReadOnlyField>
                );

            case 'boolean':
                return <Checkbox key={key} name={name} label={this.getStringResource(label)} checked={value} readOnly={true} />;
            case 'tristate':
                return (
                    <ToggleButtonTristateGroup
                        key={key}
                        name={name}
                        groupLabel={this.getStringResource(groupLabel)}
                        buttonTrueLabel={this.getStringResource(trueLabel)}
                        buttonFalseLabel={this.getStringResource(falseLabel)}
                        value={value}
                        readOnly={true}
                    />
                );
            case 'geolocation':
                return (
                    <ReadOnlyField key={key} label={translatedLabel}>
                        <span>
                            <GeoLocationIcon />
                        </span>
                        <span>{value}</span>
                    </ReadOnlyField>
                );
            case 'input': {
                const textDisplayConfig = {
                    ...config,
                    maxLength: config.viewMaxLength || config.maxLength,
                };

                return (
                    <ReadOnlyField key={key} label={translatedLabel} multiline={multiline}>
                        <TextDisplay value={value} config={textDisplayConfig} getStringResource={this.getStringResource} />
                    </ReadOnlyField>
                );
            }

            case 'toggleswitch':
                return (
                    <ToggleSwitch
                        key={key}
                        id={id}
                        readOnly={true}
                        name={name}
                        label={translatedLabel}
                        checked={checked !== undefined ? checked : value}
                    />
                );
            case 'date-range': {
                const sValue = this.props.record.getValue(startName);
                const eValue = this.props.record.getValue(endName);

                return (
                    <div key={key} className="wcux-nxt-display-readonly-date-range">
                        <div className="wcux-nxt-display-readonly-date-range-field" data-testid="display-readonly-field">
                            <label
                                className="wcux-nxt-display-readonly-date-range-field-label wcux-nxt-label"
                                data-testid="display-readonly-field-label"
                            >
                                {startLabel ? this.getStringResource(startLabel) : 'From'}
                            </label>
                            <div
                                className="wcux-nxt-display-readonly-date-range-field-content"
                                data-testid="display-readonly-field-content"
                            >
                                {sValue && moment(sValue).toDate().toLocaleDateString()}
                            </div>
                        </div>
                        <div className="wcux-nxt-display-readonly-date-range-field" data-testid="display-readonly-field">
                            <label
                                className="wcux-nxt-display-readonly-date-range-field-label wcux-nxt-label"
                                data-testid="display-readonly-field-label"
                            >
                                {endLabel ? this.getStringResource(endLabel) : 'To'}
                            </label>
                            <div
                                className="wcux-nxt-display-readonly-date-range-field-content"
                                data-testid="display-readonly-field-content"
                            >
                                {eValue && moment(eValue).toDate().toLocaleDateString()}
                            </div>
                        </div>
                    </div>
                );
            }

            default:
                return (
                    <ReadOnlyField key={key} label={translatedLabel}>
                        {value}
                    </ReadOnlyField>
                );
        }
    }

    /**
     * Render a text input control
     *
     * @param {object} config - input config
     * @param {string} key - key
     * @returns {Input} input control
     */
    renderTextInput(config, key) {
        const {
            id,
            label,
            placeholder,
            description,
            mandatory,
            name,
            readOnly,
            disabled,
            multiline,
            rows,
            minLength,
            maxLength,
            pattern,
            showCounter,
        } = config;

        let value, handleChange;
        const translatedLabel = this.getStringResource(label);
        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.INPUT, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event.target.value);
        }

        const inputAttr = {
            minLength: minLength,
            maxLength: maxLength,
            pattern: pattern,
        };

        const isResizable = maxLength > this.props.inputResizableThreshold;

        return (
            <Input
                key={key}
                id={id}
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                multiline={multiline}
                isResizable={isResizable}
                rows={rows}
                inputAttr={inputAttr}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
                showCounter={showCounter}
            />
        );
    }

    /**
     * Render a GeoLocation control
     *
     * @param {object} config - GeoLocation config
     * @param {string} key - key
     * @returns {GeoLocation} GeoLocation control
     */
    renderGeoLocation(config, key) {
        const {
            id,
            label,
            placeholder,
            description,
            mandatory,
            name,
            permissionError,
            unavailableError,
            timeoutError,
            defaultError,
            accuracyThreshold,
            exceedThresholdMsg,
            captureInProgressMsg,
            applyButtonText,
            cancelButtonText,
            readOnly,
        } = config;

        let value, handleChange;
        const translatedLabel = this.getStringResource(label);
        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.GEOLOCATION, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event.value);
        }

        return (
            <GeoLocation
                key={key}
                id={id}
                name={name}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
                permissionError={permissionError ? this.getStringResource(permissionError) : undefined}
                unavailableError={unavailableError ? this.getStringResource(unavailableError) : undefined}
                timeoutError={timeoutError ? this.getStringResource(timeoutError) : undefined}
                defaultError={defaultError ? this.getStringResource(defaultError) : undefined}
                accuracyThreshold={accuracyThreshold}
                exceedThresholdMsg={exceedThresholdMsg ? this.getStringResource(exceedThresholdMsg) : undefined}
                captureInProgressMsg={captureInProgressMsg ? this.getStringResource(captureInProgressMsg) : undefined}
                applyButtonText={applyButtonText ? this.getStringResource(applyButtonText) : undefined}
                cancelButtonText={cancelButtonText ? this.getStringResource(cancelButtonText) : undefined}
                readOnly={readOnly}
            />
        );
    }

    /**
     * Render a ToggleSwitch control
     *
     * @param {object} config - ToggleSwitch config
     * @param {string} key - key
     * @returns {ToggleSwitch} ToggleSwitch control
     */
    renderToggleSwitch(config, key) {
        const { name, label, id, readOnly, disabled, checked } = config;

        let value, handleChange;

        if (name) {
            value = this.props.record.getValue(name);
            handleChange = (event) => this.handleChange(name, event.isChecked);
        }

        return (
            <ToggleSwitch
                key={key}
                id={id}
                readOnly={readOnly}
                disabled={disabled}
                name={name}
                label={this.getStringResource(label)}
                onChange={handleChange}
                checked={checked !== undefined ? checked : value}
            />
        );
    }

    /**
     * Render a number input control
     *
     * @param {object} config - input config
     * @param {string} key - key
     * @returns {Input} input control
     */
    renderNumberInput(config, key) {
        const { id, label, placeholder, description, mandatory, name, readOnly, disabled, max, min, integerOnly, precision } = config;
        const inputAttr = { max, min, precision };
        let value, handleChange;
        const translatedLabel = this.getStringResource(label);

        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.NUMBER, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event.target.value);
        }

        return (
            <Input
                type="number"
                key={key}
                id={id}
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
                inputAttr={inputAttr}
                integerOnly={integerOnly}
            />
        );
    }

    /**
     * Render a duration input control
     *
     * @param {object} config - input config
     * @param {string} key - key
     * @returns {Input} input control
     */
    renderDurationInput(config, key) {
        const { id, label, placeholder, description, mandatory, name, readOnly, disabled, integerOnly, format, precision, source } = config;
        const inputAttr = { format, precision, source };
        let value, handleChange, type;
        const translatedLabel = this.getStringResource(label);

        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.DURATION, mandatory, name, value, description, translatedLabel);
        }

        if (format) {
            type = 'duration';
            handleChange = (formattedValue) => this.handleChange(name, formattedValue);
        } else {
            type = 'number';
            handleChange = (event) => this.handleChange(name, event.target.value);
        }

        return (
            <Input
                type={type}
                key={key}
                id={id}
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
                inputAttr={inputAttr}
                integerOnly={integerOnly}
            />
        );
    }

    /**
     * Render a dropdown control
     *
     * @param {object} config - dropdown config
     * @param {string} key - key
     * @returns {Dropdown} dropdown control
     */
    renderDropdown(config, key) {
        const { id, label, placeholder, description, data, mandatory, name, readOnly, disabled, isClearable, isMulti, showActionButton } =
            config;

        const { childMenuPlacement, childMenuPosition } = this.props;

        let value, handleChange;

        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.DROPDOWN, mandatory, name, value, description, this.getStringResource(label));
            handleChange = (selected) => this.handleChange(name, selected.value);
        }

        return (
            <FormDropdown
                key={key}
                id={id}
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                label={label}
                placeholder={placeholder}
                description={description}
                mandatory={mandatory}
                isClearable={isClearable}
                isMulti={isMulti}
                value={value}
                onChange={handleChange}
                showError={this.showError(name)}
                onActionButtonClick={showActionButton ? (name) => this.handleDropdownActionButtonClick(name) : undefined}
                actionButtonIcon={this.getActionButtonIcon(name)}
                getStringResource={this.getStringResource}
                dataConfig={data}
                formDataConfig={this.props.configData}
                record={this.props.record}
                hostUrl={this.props.hostUrl}
                getToken={this.props.getToken}
                menuPlacement={childMenuPlacement}
                menuPosition={childMenuPosition}
            />
        );
    }

    /**
     * Render a date/time/datetime control
     *
     * @param {object} config - datetime config
     * @param {string} key - key
     * @returns {Input} date/time/datetime control
     */
    renderDateTime(config, key) {
        const { type, id, label, placeholder, description, mandatory, name, readOnly, disabled } = config;

        let dtType = type;

        if (dtType === ConfigType.DATETIME) {
            dtType = 'datetime-local';
        }

        let value, handleChange;
        const translatedLabel = this.getStringResource(label);
        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.DATETIME, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event.target.value);
        }

        return (
            <Input
                key={key}
                type={dtType}
                id={id}
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
            />
        );
    }

    renderDateRange(config, key) {
        const { id, placeholder, description, startLabel, endLabel, mandatory, startName, endName, readOnly, disabled, format } = config;
        const { record } = this.props;
        const sValue = record.getValue(startName);
        const eValue = record.getValue(endName);
        this.setMandatoryError(ConfigType.DATERANGE, mandatory, startName, sValue, description);
        this.setMandatoryError(ConfigType.DATERANGE, mandatory, endName, eValue, description);

        const handleStartChange = (value) => this.handleChange(startName, value);
        const handleEndChange = (value) => this.handleChange(endName, value);
        return (
            <DayRangePicker
                key={key}
                id={id}
                startName={startName}
                endName={endName}
                start={sValue}
                end={eValue}
                readOnly={readOnly}
                disabled={disabled}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                format={format}
                onStartChange={handleStartChange}
                onEndChange={handleEndChange}
                error={this.showError(startName) || this.showError(endName)}
                startLabel={this.getStringResource(startLabel)}
                endLabel={this.getStringResource(endLabel)}
            />
        );
    }

    /**
     * Render a Relative Date control
     *
     * @param {object} config - Relative Date config
     * @param {string} key - key
     * @returns {RelativeDatePicker} Relative Date control
     */
    renderRelativeDate(config, key) {
        const {
            id,
            label,
            absoluteLabel,
            relativeLabel,
            dateTimePart,
            description,
            mandatory,
            name,
            readOnly,
            disabled,
            relativeDateValueOptions,
        } = config;

        const { childMenuPlacement, childMenuPosition } = this.props;

        const translatedLabel = this.getStringResource(label);

        let value, handleChange;
        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.DATERANGE, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event);
        }

        return (
            <RelativeDatePicker
                id={id}
                key={key}
                label={translatedLabel}
                absoluteLabel={this.getStringResource(absoluteLabel) || 'Absolute'}
                relativeLabel={this.getStringResource(relativeLabel) || 'Relative'}
                mandatory={mandatory}
                error={this.showError(translatedLabel)}
                initialDateInfo={value}
                onChange={handleChange}
                getStringResource={this.getStringResource}
                dateTimePart={dateTimePart}
                disabled={disabled}
                readOnly={readOnly}
                relativeDateValueOptions={relativeDateValueOptions}
                menuPlacement={childMenuPlacement}
                menuPosition={childMenuPosition}
            />
        );
    }

    /**
     * Render an image grid
     *
     * @param {object} config - image grid config
     * @param {string} key - key
     * @returns {ImageGrid} - image grid
     */
    renderImageGrid(config, key) {
        const { cellHeight, columns, showCamera, showDelete, name, width } = config;
        const { callbacks } = this.props;
        const images = this.props.record.getValue(name) || [];

        let handleCameraClick;
        let handlePhotoClick;
        let handleDeleteClick;
        if (callbacks && callbacks.ImageGrid) {
            const { onCameraClick, onPhotoClick, onDeleteClick } = callbacks.ImageGrid;
            const update = (newImages) => this.handleChange(name, newImages);

            if (onCameraClick) {
                handleCameraClick = () => onCameraClick(images.slice(), update);
            }

            if (onPhotoClick) {
                handlePhotoClick = () => onPhotoClick(images.slice(), update);
            }

            if (onDeleteClick) {
                handleDeleteClick = (index) => onDeleteClick(index, images.slice(), update);
            }
        }

        return (
            <ImageGrid
                key={key}
                showCamera={showCamera}
                showDelete={showDelete}
                onCameraClick={handleCameraClick}
                onPhotoClick={handlePhotoClick}
                onDeleteClick={handleDeleteClick}
                cellHeight={cellHeight}
                width={width}
                columns={columns}
                images={images}
            />
        );
    }

    /**
     * Render a boolean control
     *
     * @param {object} config - checkbox config
     * @param {string} key - key
     * @returns {Checkbox} checkbox control
     */
    renderBoolean(config, key) {
        const { name, label, readOnly } = config;

        let value, handleChange;

        if (name) {
            value = this.props.record.getValue(name);
            handleChange = (event) => this.handleChange(name, event.target.checked);
        }

        return (
            <Checkbox
                key={key}
                name={name}
                label={this.getStringResource(label)}
                onChange={handleChange}
                checked={value}
                readOnly={readOnly}
            />
        );
    }

    /**
     * Render a tristate control
     *
     * @param {object} config - tristate config
     * @param {string} key - key
     * @returns {ToggleButtonTristateGroup} tristate control
     */
    renderTristate(config, key) {
        const { name, trueLabel, falseLabel, groupLabel, readOnly, mandatory } = config;

        let value, handleChange;

        if (name) {
            value = this.props.record.getValue(name);
            handleChange = (name, value) => this.handleChange(name, value);
        }

        return (
            <ToggleButtonTristateGroup
                key={key}
                name={name}
                groupLabel={this.getStringResource(groupLabel)}
                buttonTrueLabel={this.getStringResource(trueLabel)}
                buttonFalseLabel={this.getStringResource(falseLabel)}
                onChange={handleChange}
                value={value}
                readOnly={readOnly}
                mandatory={mandatory}
            />
        );
    }

    /**
     * Renders the editable table and calls the callbacks on add,edit,delete
     *
     * @param {object} config - the configuration for the control
     * @param {string} key - The key of the control
     * @param {object} record - The record that the data is read from
     * @param {function} onAdd - Callback when the add button is pressed
     * @param {function} onEdit - Callback when the edit button is pressed
     * @param {function} onDelete - Callback when the delete button is pressed
     * @returns {ReadOnlyTable} - The Component to display
     */
    renderEditableTable(config, key, record, onAdd, onEdit, onDelete) {
        const { name, columns, label, minItems, description } = config;

        const value = record.getValue(name);
        let columnsDefinition = convertToReadOnlyTableColumnDefinitions(
            columns,
            this.props.configData,
            this.props.callbacks,
            this.getStringResource
        );

        const translatedLabel = this.getStringResource(label);
        const handleChange = (name, value) => this.handleChange(name, value);
        this.setMandatoryError(ConfigType.TABLE, minItems > 0, name, value, description, translatedLabel, minItems);

        // Need to add the edit/delete buttons at the end of the column definition
        columnsDefinition.push({
            className: 'wcux-nxt-form-table-row-btn-container',
            id: 'actions',
            align: 'right',
            actionButtons: [
                {
                    key: 'editBtn',
                    className: 'wcux-nxt-form-table-edit-btn',
                    variant: 'discrete-black',
                    icon: <Edit />,
                    handleClick: (e, rowIndex) => onEdit(rowIndex, config, record, handleChange),
                },
                {
                    key: 'deleteBtn',
                    className: 'wcux-nxt-form-table-delete-btn',
                    variant: 'discrete-black',
                    icon: <Trash />,
                    handleClick: (e, rowIndex) => onDelete(rowIndex, config, record, handleChange),
                },
            ],
        });

        const actionButtons = [
            {
                className: 'wcux-nxt-form-table-add-btn',
                variant: 'primary',
                key: 'addButton',
                icon: <Plus />,
                handleClick: () => onAdd(config, record, handleChange),
            },
        ];

        return (
            <ReadOnlyTable
                key={key}
                className="wcux-nxt-editable-table"
                data={value}
                columns={columnsDefinition}
                actionButtons={actionButtons}
                dense={true}
                title={translatedLabel}
                description={this.getStringResource(description)}
                minItems={minItems}
                error={this.showError(name)}
            />
        );
    }

    /**
     * Render an Attachment Control
     *
     * @param {object} config - attachment control (file-attachment) config
     * @param {string} key - key
     * @returns {AttachmentControl} - attachment control
     */
    renderFileAttachment(config, key) {
        const {
            cellHeight,
            name,
            gridColumnsCount,
            todayLabel,
            allowedFileExtensions,
            showUploadMode,
            width,
            title,
            showThumbnails,
            isMulti,
            allowDragAndDrop,
            yesterdayLabel,
            zeroByteLabel,
            bytesLabel,
            kbLabel,
            gbLabel,
            tbLabel,
            mbLabel,
            addLabel,
            enableLightbox,
            acceptedFormatTitle,
            uploadLabel,
            showAttachmentsCount,
            mandatory,
            label,
            description,
        } = config;

        const { callbacks } = this.props;
        const attachments = this.props.record.getValue(name) || [];

        this.setMandatoryError(ConfigType.FILE_ATTACHMENTS, mandatory, name, attachments, description, this.getStringResource(label));

        let handleInputChange;
        let handleDeleteClick;
        let handleFileDropError;

        if (callbacks && callbacks[config.type]) {
            const { onInputChange, onDeleteClick, onFileDropError } = callbacks[config.type];
            const update = (newAttachments) => this.handleChange(name, newAttachments);

            if (onInputChange) {
                handleInputChange = (newFileList) => onInputChange(newFileList, attachments, update);
            }

            if (onDeleteClick) {
                handleDeleteClick = (index) => onDeleteClick(index, attachments.slice(), update);
            }

            if (onFileDropError) {
                handleFileDropError = (FileList) => onFileDropError(FileList);
            }
        }

        let labelView;
        if (label) {
            labelView = (
                <label className={classNames('wcux-nxt-label', { 'wcux-nxt-mandatory-indicator': mandatory })}>
                    {this.getStringResource(label)}
                </label>
            );
        }

        return (
            <>
                {labelView}
                <AttachmentControl
                    key={key}
                    name={name}
                    showUploadMode={showUploadMode}
                    gridColumnsCount={gridColumnsCount}
                    cellHeight={cellHeight}
                    width={width}
                    title={this.getStringResource(title || label)}
                    allowedFileExtensions={allowedFileExtensions}
                    files={attachments}
                    onInputChange={handleInputChange}
                    onDeleteClick={handleDeleteClick}
                    todayLabel={this.getStringResource(todayLabel)}
                    yesterdayLabel={this.getStringResource(yesterdayLabel)}
                    zeroByteLabel={this.getStringResource(zeroByteLabel) || '0 Bytes'}
                    bytesLabel={this.getStringResource(bytesLabel) || 'Bytes'}
                    kbLabel={this.getStringResource(kbLabel) || 'Kb'}
                    gbLabel={this.getStringResource(gbLabel) || 'Gb'}
                    tbLabel={this.getStringResource(tbLabel) || 'Tb'}
                    mbLabel={this.getStringResource(mbLabel) || 'Mb'}
                    addLabel={this.getStringResource(addLabel)}
                    enableLightbox={enableLightbox}
                    acceptedFormatTitle={this.getStringResource(acceptedFormatTitle)}
                    onFileDropError={handleFileDropError}
                    uploadLabel={this.getStringResource(uploadLabel)}
                    showThumbnails={showThumbnails}
                    isMulti={isMulti}
                    allowDragAndDrop={allowDragAndDrop}
                    showAttachmentsCount={showAttachmentsCount}
                />
            </>
        );
    }

    /**
     * Get the string with the specified key
     *
     * @param {string} key - string resource key
     * @returns {string} string with the specified key
     */
    getStringResource(key) {
        if (typeof this.props.getStringResource === 'function') {
            return this.props.getStringResource(key);
        }

        return key;
    }

    /**
     * Set mandatory field error
     *
     * @param {string} type - config type
     * @param {boolean} mandatory - true if mandatory field
     * @param {string} name - field name
     * @param {*} value - field value
     * @param {string} description - field description
     * @param {string} label - field label
     * @param {number} min - minimum value
     */
    setMandatoryError(type, mandatory, name, value, description, label, min) {
        this.mandatoryErrors[name] = {
            showError: false,
            // used to show the field label inside the error dialog,
            // otherwise, if we don't put it here whole config will again have to be traversed to get the field labels
            label: label ? label : name,
        };

        if (!mandatory) {
            return;
        }

        let showError = false;

        if (type === ConfigType.DROPDOWN) {
            // For dropdowns allow empty string as an option
            // If it's an array (multi option), dont allow empty arrays if mandatory
            showError = value === null || (Array.isArray(value) && value.length === 0);
        } else if (type === ConfigType.IMAGEGRID) {
            showError = !Array.isArray(value) || value.length === 0;
        } else if (type === ConfigType.TABLE) {
            showError = !Array.isArray(value) || value.length < min;
        } else {
            showError = value === null || value === '' || (Array.isArray(value) && value.length === 0);
        }

        if (showError) {
            this.mandatoryErrors[name].showError =
                (description && this.getStringResource(description)) || this.props.labels.mandatory || true;
        }
    }

    /**
     * Show error for the given field
     *
     * @param {string} name - field name
     * @returns {string|boolean} error string or true to show error indicator or false for no error
     */
    showError(name) {
        if (this.state.showErrors) {
            return this.getError(name);
        }

        return false;
    }

    /**
     *
     * @param {string} name - name of the field
     * @returns {string|boolean} error string or true to show error indicator or false for no error
     */
    getError(name) {
        if (this.mandatoryErrors[name].showError) {
            return this.mandatoryErrors[name].showError;
        }

        if (this.validationErrors[name]) {
            return this.validationErrors[name];
        }

        return false;
    }

    /**
     * Show all field errors
     */
    showErrors() {
        this.setState({ showErrors: true });
    }

    /**
     * Handle field change event
     *
     * @param {string} name - field name
     * @param {*} value - field value
     */
    handleChange(name, value) {
        let fieldError;

        // enforce pattern (RegEx match) only when value is populated
        // send null back instead of an empty string
        if (this.fieldsWithPattern[name]) {
            if (value && value.length > 0) {
                if (!this.fieldsWithPattern[name].test(value)) {
                    fieldError = true;
                }
            } else {
                value = null;
            }
        }

        this.props.record.setValue(name, value);

        // enforce min length
        if (this.fieldsWithMinLength[name] && value && value.length < this.fieldsWithMinLength[name]) {
            fieldError = true;
        }

        this.validationErrors[name] = fieldError ? true : undefined;

        if (this.props.validation && this.props.validation.validate) {
            let errors = this.props.validation.validate(name, this.props.record);

            if (typeof errors === 'object') {
                Object.assign(this.validationErrors, errors);
            }
        }

        this.setState({ hasChanges: true });

        if (typeof this.props.onFormValueChange === 'function') {
            this.props.onFormValueChange(name, value);
        }
    }

    /**
     * Handle Dropdown action button click event
     *
     * @param {string} name - name of dropdown field
     */
    handleDropdownActionButtonClick(name) {
        if (this.props.callbacks && this.props.callbacks.Dropdown && this.props.callbacks.Dropdown.onActionButtonClick) {
            this.props.callbacks.Dropdown.onActionButtonClick(name);
        }
    }

    /**
     * Handle getting Dropdown action button icon
     *
     * @param {string} name - name of dropdown field
     * @returns {React.Component|HTMLDivElement|string} element or path of action button icon
     */
    getActionButtonIcon(name) {
        if (this.props.callbacks && this.props.callbacks.Dropdown && this.props.callbacks.Dropdown.getActionButtonIcon) {
            return this.props.callbacks.Dropdown.getActionButtonIcon(name);
        }
    }

    /**
     * Validate form
     *
     * @param {object} record - form record
     * @param {object} validation - validation
     */
    validateAll(record, validation) {
        if (validation && validation.validateAll) {
            let errors = validation.validateAll(record);

            if (typeof errors === 'object') {
                this.validationErrors = errors;
            }
        }
    }

    /**
     * Clear the form
     */
    clearForm() {
        let record = this.props.record;
        record.clearRecord();
        this.validateAll(record, this.props.validation);
        this.setState({ hasChanges: true });
    }

    /**
     * Reset the form to its default values
     */
    resetForm() {
        let record = this.props.record;
        record.initData();
        this.validateAll(record, this.props.validation);
        this.setState({ showErrors: false, hasChanges: false });
    }

    /**
     * get expansion panels default state
     *
     * @returns {object} default expansion panels state
     */
    getDefaultExpansionPanelState() {
        const { config } = this.props;
        const expansionPanelState = {};

        if (Array.isArray(config.children)) {
            config.children.forEach((page, pageIndex) => {
                expansionPanelState[pageIndex] = {};
                if (Array.isArray(page.children)) {
                    page.children.forEach((section, sectionIndex) => {
                        if (section.isCollapsible && !section.disableCollapse) {
                            // ignore sections with disableCollapse because those sections are always shown
                            expansionPanelState[pageIndex][sectionIndex] = Boolean(section.defaultExpanded);
                        }
                    });
                }
            });
        }

        return expansionPanelState;
    }

    /**
     * Handle expansion panel change event
     *
     * @param {object} config - config of section
     * @param {boolean} expanded - new expanded state of current section
     * @param {number} sectionKey - section key(index) of current page
     */
    handleExpansionPanelChange(config, expanded, sectionKey) {
        // ignore sections with disableCollapse because those sections are always shown
        if (config.disableCollapse) return;

        const { expansionPanelsExpanded, selectedTab } = this.state;

        let newExpansionPanelsState = { ...expansionPanelsExpanded };
        newExpansionPanelsState[selectedTab][sectionKey] = expanded;
        this.setState({ expansionPanelsExpanded: newExpansionPanelsState });
    }

    /**
     * Renders expansion panels toggler (expand/collapse all)
     *
     * @param {object} config - the Config object for the Page
     * @returns {React.Component|HTMLDivElement} div of expansion panels toggler
     */
    renderExpansionPanelsToggler(config) {
        const { expansionPanelsExpanded, selectedTab } = this.state;
        let expansionToggleEnable = false;
        let expansionList = [];
        if (config.enableExpansionPanelsToggle && config.children.some((section) => section.isCollapsible)) {
            expansionList = Object.keys(expansionPanelsExpanded[selectedTab]).map(
                (sectionKey) => expansionPanelsExpanded[selectedTab][sectionKey]
            );
            expansionToggleEnable = true;
        }

        if (expansionToggleEnable) {
            /**
             * Handles setting the current page's expansion panel state
             *
             * @param {boolean} expanded - boolean to control expand/collapse all panels on page
             */
            const handleExpansionToggle = (expanded) => {
                let newExpansionPanelsState = { ...expansionPanelsExpanded };
                Object.keys(newExpansionPanelsState[selectedTab]).forEach((sectionKey) => {
                    newExpansionPanelsState[selectedTab][sectionKey] = expanded;
                });
                this.setState({ expansionPanelsExpanded: newExpansionPanelsState });
            };

            return (
                <div className="wcux-nxt-expansion-panel-toggle-wrapper">
                    {expansionList.includes(false) ? (
                        <div className="wcux-nxt-expansion-panel-toggle-expand" onClick={() => handleExpansionToggle(true)}>
                            {this.getStringResource(config.expandAllLabel)}
                        </div>
                    ) : (
                        <div className="wcux-nxt-expansion-panel-toggle-collapse" onClick={() => handleExpansionToggle(false)}>
                            {this.getStringResource(config.collapseAllLabel)}
                        </div>
                    )}
                </div>
            );
        }
    }

    /**
     * Construct the title when the expansion panel is collapsed
     *
     * @param {string} title - title of the section
     * @param {object} fieldInfo - right section of the title
     * @return {React.Component|HTMLDivElement} div of collapsed title
     */
    getCollapsedTitle(title, fieldInfo) {
        let collapsedTitle = (
            <div className="wcux-nxt-section-collapsed-title">
                <div className="wcux-nxt-section-collapsed-title-left">{this.getStringResource(title)}</div>
                <div className="wcux-nxt-section-collapsed-title-right">{fieldInfo}</div>
            </div>
        );

        return collapsedTitle;
    }
}

Form.defaultProps = {
    labels: {},
    childMenuPlacement: 'auto',
    childMenuPosition: 'fixed',
};

Form.propTypes = {
    /** Callbacks for form events */
    callbacks: PropTypes.object,
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** Form configuration */
    config: PropTypes.object.isRequired,
    /** Configuration data */
    configData: PropTypes.object.isRequired,
    /** Function for getting a string resource. Signature: getStringResource(key) */
    getStringResource: PropTypes.func.isRequired,
    /** Form record */
    record: PropTypes.object.isRequired,
    /** Form/field validation */
    validation: PropTypes.object,
    /** Array containing mandatory field names.  It supports all types except for Checkbox and Tristate.  FormBuilder does not support this */
    mandatoryFields: PropTypes.arrayOf(PropTypes.string),
    /** Array containing ready only field names. FormBuilder does not support this */
    readOnlyFields: PropTypes.arrayOf(PropTypes.string),
    /** Boolean indicating if the form is in read only mode or editable (default mode) */
    isReadOnly: PropTypes.bool,
    /** Array containing hidden field names. FormBuilder does not support this */
    hiddenFields: PropTypes.arrayOf(PropTypes.string),
    /** Function for listening to any form on changes, returning the name of the field and the new value */
    onFormValueChange: PropTypes.func,
    /** Host url */
    hostUrl: PropTypes.string,
    /** Callback for getting auth token */
    getToken: PropTypes.func,
    /** Generic label for eg. mandatory fields */
    labels: PropTypes.shape({
        mandatory: PropTypes.string,
    }),
    /**Numeric value to determine whether a text input should be rendered as a resizable text-area or not */
    inputResizableThreshold: PropTypes.number,
    // Every form child having dropdown/menu functionality will be passed this global placement prop
    childMenuPlacement: PropTypes.oneOf(menuPlacementValues),
    // Every form child having dropdown/menu functionality will be passed this global position prop
    childMenuPosition: PropTypes.oneOf(menuPositionValues),
};

export default styled(Form)`
    ${formStyles}
`;
