/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';

export default function AccountTreeTwoTone(props) {
    return <AccountTreeTwoToneIcon {...props} />;
}
