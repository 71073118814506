/**
 * @file Header component
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title, ActionButtons, Subtitle, LeftSection } from './styled';
import { Button } from '../';
import { Tooltip } from '../';
import { BackArrow } from '../Icons';
import { variantTypes, statusVariantTypes } from '../Button/Button';

const Header = ({ title, subtitle, onBackClick, actionButtons }) => {
    const actionButtonComponents = actionButtons.map((button, index) => (
        <Button
            key={'button' + index}
            variant={button.variant ? button.variant : index === 0 ? 'primary' : 'secondary'}
            {...{ onClick: button.onClick }}
            data-testid={`wcux-nxt-header-action-button-${index}`}
        >
            {button.text}
        </Button>
    ));

    return (
        <Container>
            <LeftSection>
                {onBackClick && (
                    <Button
                        variant="secondary-ultra-discrete"
                        startIcon={{ justify: 'left', icon: <BackArrow /> }}
                        onClick={onBackClick}
                        data-testid="wcux-header-back-button"
                    />
                )}
                <Tooltip title={title}>
                    <Title data-testid="wcux-nxt-header-title">{title}</Title>
                </Tooltip>
                <Tooltip title={subtitle}>
                    <Subtitle data-testid="wcux-nxt-header-subtitle">{subtitle}</Subtitle>
                </Tooltip>
            </LeftSection>
            <ActionButtons>{actionButtonComponents}</ActionButtons>
        </Container>
    );
};

Header.defaultProps = {
    actionButtons: [],
};

Header.propTypes = {
    /** Main title for the header */
    title: PropTypes.string,
    /** subtitle for the header */
    subtitle: PropTypes.string,
    /** function to call when the user click on back button. If none provided, it will hide the back button*/
    onBackClick: PropTypes.func,
    /** array of {text, onClick, variant} for action buttons. First item is primary action*/
    actionButtons: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            variant: PropTypes.oneOf([...Object.keys(variantTypes), ...Object.keys(statusVariantTypes)]),
        })
    ),
};

export default Header;
