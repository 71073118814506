/**
 * @module webcore-ux/nextgen/components/ProgressBar
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaterialLinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

const CustomCircularProgress = ({ className, ...other }) => {
    const classes = useStyles();

    return (
        <div className={classNames('wcux-nxt-progress-bar-root', classes.root)} data-testid="wcux-nxt-circular-progress">
            <CircularProgress
                variant="determinate"
                className={classNames('wcux-nxt-progress-bar-bottom', classes.bottom)}
                size={40}
                thickness={4}
                value={100}
            />
            <CircularProgress
                className={classNames('wcux-nxt-progress-bar-top', classes.top, className)}
                classes={{
                    circle: classes.circle,
                    colorPrimary: 'wcux-nxt-progress-bar-background-color',
                }}
                {...other}
            />
        </div>
    );
};

CustomCircularProgress.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
};

const CircularProgressWithIcon = ({ icon, progressBar }) => {
    let progressIcon = null;
    if (icon) {
        progressIcon = (
            <div className="wcux-nxt-progress-icon" data-testid={'wcux-nxt-circular-progress-image'}>
                {React.isValidElement(icon) ? icon : <img src={icon} />}
            </div>
        );
    }

    return (
        <Box position="relative" display="inline-flex">
            {progressBar}
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                {progressIcon}
            </Box>
        </Box>
    );
};

CircularProgressWithIcon.propTypes = {
    /** Icon to be displayed within the circular progress bar */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Actual circular progress bar component which will be wrapped inside a Box to make icon appear in center */
    progressBar: PropTypes.node,
};

const ProgressBar = ({ className, variant, value, color, type, icon, ...other }) => {
    let progressBar = null;

    if (type === 'linear') {
        progressBar = (
            <MaterialLinearProgress
                className={classNames(className, 'wcux-nxt-progress-bar')}
                classes={{
                    colorPrimary: 'wcux-nxt-progress-bar-background-color',
                    barColorPrimary: 'wcux-nxt-progress-bar-forecolor',
                }}
                variant={variant}
                color={color}
                value={value}
                {...other}
            />
        );
    }

    if (type === 'circular') {
        progressBar = (
            <CustomCircularProgress
                variant={variant}
                className={classNames(className, 'wcux-nxt-progress-bar-circular')}
                classes={{
                    colorPrimary: 'wcux-nxt-progress-bar-background-color',
                }}
                color={color}
                value={value}
                {...other}
            />
        );

        if (icon) {
            return CircularProgressWithIcon({ icon, progressBar });
        }
    }

    return progressBar;
};

ProgressBar.defaultProps = {
    variant: 'indeterminate',
    color: 'primary',
    type: 'linear',
};

ProgressBar.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** Type of the loading indicator, either determinate or indeterminate.  Defaults to indeterminate if not specified. */
    variant: PropTypes.oneOf(['buffer', 'determinate', 'indeterminate', 'query']),
    /** Percentage complete to display on the progress bar, valid range is 0-100.  Only applies to determinate variant */
    value: PropTypes.number,
    /** Color variant for the progress bar foreground and background */
    color: PropTypes.oneOf(['primary', 'secondary']),
    /** Type of loader, either Linear or Circular. Defaults to Linear if not specified. */
    type: PropTypes.oneOf(['linear', 'circular']),
    /** Icon to show inside circular progress. Can be an img src string or React node such as svg. Only applies to circular progress bar */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ProgressBar;
