/**
 * Constants for table cell/row statuses.
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import ErrorCircle from '../Icons/ErrorCircle';
import WarningCircle from '../Icons/WarningCircle';
import CheckMarkCircle from '../Icons/CheckMarkCircle';
import InformationCircle1 from '../Icons/InformationCircle1';

/*
 * Order matters: entries higher in the object will take higher precedence. Eg if a row has both error and warning, it will
 * show the error instead.
 */
export const STATUSES = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    info: 'info',
};

/**
 * Map statuses to their respective icons
 */
export const STATUS_ICONS = {
    [STATUSES.error]: ErrorCircle,
    [STATUSES.warning]: WarningCircle,
    [STATUSES.success]: CheckMarkCircle,
    [STATUSES.info]: InformationCircle1,
};

/**
 * Maps row spacing to height in pixels.
 */
export const ROW_SPACING_HEIGHT_MAP = {
    regular: 48,
    condensed: 40,
    relaxed: 56,
};

/**
 * Gets the height in pixels depending on the provided row spacing.
 * Defaults to the "regular" row spacing value if nothing is provided.
 * @param {string} rowSpacing the row spacing
 * @returns {number} height in pixels
 */
export const getHeight = (rowSpacing) => {
    return rowSpacing ? ROW_SPACING_HEIGHT_MAP[rowSpacing] : ROW_SPACING_HEIGHT_MAP.regular;
};
