/**
 * @file Component that renders the status stripe for an app
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

export const IDS = {
    statusStripe: 'wcux-nxt-status-stripe',
    message: 'wcux-nxt-status-stripe-message',
    action: 'wcux-nxt-status-stripe-action',
};

// JSS styling for this component
const useStyles = makeStyles((theme) => ({
    statusStripe: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderBottom: `1px solid ${theme.palette.grey[208]}`,
    },
    info: {
        background: theme.palette.info.main,
        color: theme.palette.info.contrastText,
    },
    success: {
        background: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    warning: {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    error: {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    message: {
        fontWeight: '400',
        fontSize: theme.typography.fontSizes.large,
        fontFamily: theme.typography.fontFamily,
        marginRight: `${theme.spacing(1)}px`,
    },
    actions: {
        cursor: 'pointer',
        fontWeight: '700',
        fontSize: theme.typography.fontSizes.large,
        fontFamily: theme.typography.fontFamily,
        textDecoration: 'underline',
        marginRight: `${theme.spacing(1)}px`,
    },
}));

/**
 * renders the an status stripe
 * @param props - the properties for the component
 * @returns {JSX.Element}
 * @constructor
 */

export const StatusStripe = ({ message, severity, actions }) => {
    const classes = useStyles();

    const renderActions = () => {
        return actions.map(({ id, text, onClick }) => (
            <span
                key={`action-${id}`}
                className={classNames(`${IDS.action}-${id}`, classes.actions)}
                data-testid={`${IDS.action}-${id}`}
                onClick={onClick}
            >
                {text}
            </span>
        ));
    };

    return (
        <div
            className={classNames(IDS.statusStripe, classes.statusStripe, classes[severity])}
            data-testid={`${IDS.statusStripe}-${severity}`}
        >
            <span className={classNames(IDS.message, classes.message)} data-testid={IDS.message}>
                {message}
            </span>
            {actions && renderActions()}
        </div>
    );
};

StatusStripe.defaultProps = {
    severity: 'info',
};

StatusStripe.propTypes = {
    /** Locales to be displayed on the status stripe */
    message: PropTypes.string,
    /** Severity of status stripe */
    severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    /** Enable actions for status stripe */
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            /** action id */
            id: PropTypes.string.isRequired,
            /** action text */
            text: PropTypes.string.isRequired,
            /** action callback */
            onClick: PropTypes.func.isRequired,
        })
    ),
    /** Custom class name on the root element */
    className: PropTypes.string,
};

export default StatusStripe;
