/**
 * @file Relative Date Picker component
 *  @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useLayoutEffect, useState } from 'react';
import { Input, Dropdown } from '../../components';
import { menuPlacementValues, menuPositionValues, closeMenuOnScrollValues } from '../../components/Dropdown/Dropdown';
import ErrorCircle from '../Icons/ErrorCircle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import * as constants from './constants.js';

const useStyles = makeStyles(() => ({
    root: {
        maxHeight: '50px',
        marginBottom: '30px',
    },
    dateTypeContainer: {
        float: 'right',
    },
    controlContainer: {
        display: 'flex',
        width: '100%',
        height: '25px',
    },
    labelControl: {
        float: 'left',
    },
    buttonControl: {
        float: 'right',
        height: '25px',
        paddingBottom: '20px',
    },
    typography: {
        float: 'right',
        padding: '2px',
    },
    absoluteEditorContainer: {
        height: '52px',
    },
    relativeEditorContainer: {
        display: 'flex',
        width: '100%',
        height: '40px',
    },
    numberControl: {
        float: 'left',
        width: '68px',
        minWidth: '68px',
        height: '22px',
        paddingRight: '5px',
    },
    relativeDateDropDownControl: {
        float: 'right',
        width: 'calc(100% - 73px)',
        minWidth: '90px',
        height: '22px',
    },
    messageContainer: {
        display: 'flex',
        width: '100%',
        'font-size': '14px',
    },
    messageLabel: {
        float: 'right',
        width: 'calc(100% - 25px)',
        marginTop: '5px',
    },
    messageIcon: {
        float: 'left',
        width: '16px',
        height: '16px',
        marginTop: '6px',
        marginRight: '4px',
    },
    mandatoryIndicator: {
        float: 'left',
        paddingTop: '2px',
        'font-family': 'Barlow, Helvetica, sans-serif',
        'font-size': '18px',
        color: 'red',
        marginRight: '4px',
    },
    selectedLabel: {
        textDecoration: 'none !important',
        color: '#00838F',
    },
    unSelectedLabel: {
        textDecoration: 'none !important',
        color: 'black',
    },
    separatorLabel: {
        textDecoration: 'none !important',
        color: 'black',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
}));

const isValidDate = (date) => {
    return date && date.constructor === Date && !isNaN(date.getTime());
};

/**
 * Date formatted using current locale and timezone for Date Input component.
 * @param {object} date - Date to format
 * @param {string} dateOption - absoluteDateOption value specifying whether to keep the time value of the returned formatted string
 * @returns {string} - Date string formatted with current locale and timezone.
 *
 */
const formatDateForDateControl = (date, dateOption) => {
    if (isValidDate(date)) {
        let dateString;
        if (dateOption === constants.absoluteDateOptionEnum.dateOnly) {
            // remove the time part from the date ie yyyy-MM-dd
            dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
        } else {
            // return complete date+time value ie. yyyy-MM-ddThh:mm
            dateString = new Date(date).toISOString();
        }

        return dateString;
    }

    // otherwise return empty string for null or empty date
    return '';
};

/**
 * Relative Date Picker component
 * @param {object} props - props for Relative Date Picker component
 * @returns {ReactElement} - Relative Date Picker component
 */
export const RelativeDatePicker = ({
    id,
    label,
    absoluteLabel,
    relativeLabel,
    mandatory,
    error,
    onChange,
    relativeDateValueOptions,
    initialDateInfo,
    dateTimePart,
    absoluteDateOption,
    disabled,
    readOnly,
    allowEmptyDates,
    getStringResource,
    menuPosition,
    menuPlacement,
    closeMenuOnScroll,
}) => {
    const classes = useStyles();

    const [absoluteDate, setAbsoluteDate] = useState(null);

    /* This state is specifically used to handle the case when someone is typing in a date. During this process the date may not be valid, but we need to keep
     * this around and pass it through to allow the user to continue to update the component.
     *
     * For example, when typing the year "2021" into the component, as we type it the parsed date value will result in a different value than what was typed.
     *
     * This is the event flow based on the raw value the HTML input gives us, and our value, parsed to a date, and then converted back to a YYYY-MM-DD string:
     *
     * | Raw value  | Parsed Value |
     * |------------|--------------|
     * | 0002-07-27 | 1902-07-27   |
     * | 0020-07-27 | 1920-07-27   |
     * | 0202-07-27 | 0202-07-27   |
     * | 2021-07-27 | 2021-07-27   |
     *
     * Under DateOnly option, format will be set as yyyy-mm-dd  --- eg. 2021-09-10
     * Under DateAndTime option, format will be set as yyyy-mm-ddThh:mm --- eg. 2021-09-10T00:00
     */
    const [absoluteInternalDateValue, setAbsoluteInternalDateValue] = useState(null);

    const [datePickerType, setDatePickerType] = useState(constants.dateTypeEnum.absolute);

    // user selection for the relative Date
    const [relativeDateOffset, setRelativeDateOffset] = useState(0);
    const [relativeDateOptionValue, setRelativeDateOptionValue] = useState(undefined);

    // dropdown list for the Relative Date Option
    const [relativeDateOptions, setRelativeDateOptions] = useState(undefined);

    // initial startup state
    const [loading, setLoading] = useState(true);

    const preventDefault = (event) => event.preventDefault();

    // useLayoutEffect
    // Initialize the Component
    useLayoutEffect(() => {
        if (loading) {
            // Build the options list for the Relative Date dropdown menu
            // omit the "No Date" option if Empty Dates are not allowed
            let valueOptions = null;
            if (!relativeDateOptions) {
                if (relativeDateValueOptions) {
                    valueOptions = [...relativeDateValueOptions];
                } else {
                    valueOptions = [...constants.buildRelativeDateValueOptions(constants.relativeDateValueOptions, getStringResource)];
                }

                // remove the "No Date" option if Empty Dates are not allowed
                if (allowEmptyDates === false) {
                    valueOptions = valueOptions.filter((obj) => allowEmptyDates || !constants.isEmpty(obj.hours));
                }

                setRelativeDateOptions(valueOptions);
            } else {
                valueOptions = relativeDateOptions;
            }

            // Setup Absolute Date from parameters provided by user or from default props
            let absoluteDateValue;
            let relativeDateValue;
            if (allowEmptyDates && !initialDateInfo.date) {
                // empty date
                setAbsoluteDate(null);
                setAbsoluteInternalDateValue('');
            } else {
                if (initialDateInfo.dateType === constants.dateTypeEnum.absolute) {
                    const date = initialDateInfo.date ? initialDateInfo.date : new Date();
                    if (absoluteDateOption === constants.absoluteDateOptionEnum.dateAndTime) {
                        absoluteDateValue = date; // keep the time part
                    } else {
                        absoluteDateValue = constants.createAbsoluteDate(date, dateTimePart); // adjust the time part to be either start/end of day
                    }

                    setAbsoluteDate(absoluteDateValue);
                    setAbsoluteInternalDateValue(formatDateForDateControl(absoluteDateValue, absoluteDateOption));
                } else if (initialDateInfo.dateType === constants.dateTypeEnum.relative) {
                    // calculate date value based on relativeDate options
                    const calcDateValueParams = {
                        dateType: initialDateInfo.dateType,
                        offset: initialDateInfo.relativeDateOffset,
                        optionValue: initialDateInfo.relativeDateOptionValue,
                        relativeDateOptionValues: valueOptions,
                        dateTimePart: dateTimePart,
                        absoluteDateOption: absoluteDateOption,
                    };

                    relativeDateValue = constants.calcDateValue(calcDateValueParams);
                    setAbsoluteDate(relativeDateValue);
                    setAbsoluteInternalDateValue(formatDateForDateControl(relativeDateValue, absoluteDateOption));
                }
            }

            // Set Date Type - default is absolute
            setDatePickerType(initialDateInfo.dateType);

            // Setup Relative Date Info
            setRelativeDateOffset(initialDateInfo.relativeDateOffset);
            setRelativeDateOptionValue(initialDateInfo.relativeDateOptionValue);

            // Post back the changes so the client can get the calc date as needed.
            if (initialDateInfo.dateType === constants.dateTypeEnum.absolute) {
                handleAbsoluteDateOnChange({
                    dateValue: absoluteDateValue,
                    dateType: initialDateInfo.dateType,
                    offset: initialDateInfo.relativeDateOffset,
                    optionValue: initialDateInfo.relativeDateOptionValue,
                });
            } else {
                handleRelativeDateOnChange({
                    dateType: initialDateInfo.dateType,
                    offset: initialDateInfo.relativeDateOffset,
                    optionValue: initialDateInfo.relativeDateOptionValue,
                    relativeDateOptionValues: valueOptions,
                });
            }

            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialDateInfo]);

    /**
     * Process Messages.
     * @returns {object} - Message if the error prop exists and is string.
     */
    const processMessages = () => {
        let message = null;

        if (error && typeof error === 'string') {
            // render error message if provided
            message = (
                <div data-testid="message-component-test-id" className={classes.messageContainer}>
                    <ErrorCircle data-testid="error-component-test-id" className={classes.messageIcon} color="error" />
                    <span data-testid="error-label-test-id" className={classes.messageLabel}>
                        {error}
                    </span>
                </div>
            );
        }

        return message;
    };

    /**
     * Options button click handler. Used to change between using absolute or relative dates for editing.
     * @param {string} dateType - Type Date format (absolute or relative)
     */
    const handleOptionsClick = (dateType) => {
        if (dateType !== datePickerType) {
            setDatePickerType(dateType);

            if (dateType === constants.dateTypeEnum.absolute) {
                setAbsoluteInternalDateValue(formatDateForDateControl(absoluteDate, absoluteDateOption));
                handleAbsoluteDateOnChange({
                    dateValue: absoluteDate,
                    dateType: dateType,
                    offset: relativeDateOffset,
                    optionValue: relativeDateOptionValue,
                });
            } else {
                handleRelativeDateOnChange({ dateType: dateType, offset: relativeDateOffset, optionValue: relativeDateOptionValue });
            }
        }
    };

    /**
     * Option Button Style based on Button type.
     * @param {string} buttonType - Button type.
     * @returns {string} - class style based on button type.
     */
    const optionButtonStyle = (buttonType) => {
        if (
            (buttonType === constants.dateTypeEnum.absolute && datePickerType === constants.dateTypeEnum.relative) ||
            (buttonType === constants.dateTypeEnum.relative && datePickerType === constants.dateTypeEnum.absolute)
        ) {
            return classes.unSelectedLabel;
        }

        return classes.selectedLabel;
    };

    /**
     * Absolute Date On Change event handler.
     * @param {object} params - { dateValue, dateType, offset, optionValue }
     */
    const handleAbsoluteDateOnChange = (params) => {
        // resolve date
        let adjustedDate;
        if (absoluteDateOption === constants.absoluteDateOptionEnum.dateOnly) {
            adjustedDate = !isValidDate(params.dateValue) ? null : constants.createAbsoluteDate(new Date(params.dateValue), dateTimePart);
        } else {
            adjustedDate = !isValidDate(params.dateValue) ? null : new Date(params.dateValue);
        }

        if (!loading) {
            setAbsoluteDate(adjustedDate);
        }

        // invoke onChange callback
        if (onChange) {
            // {
            //     date: value,
            //     dateType: value (0=absolute (default), 1=relative),
            //     relativeDateOffset: value (0 is default),
            //     relativeDateOption: value (undefined is default)
            // }
            const dateInfo = {
                date: adjustedDate,
                dateType: params.dateType,
                relativeDateOffset: params.offset,
                relativeDateOptionValue: params.optionValue,
            };

            onChange(dateInfo);
        }
    };

    /**
     * Relative Date On Change event handler.
     * @param {object} params - { dateType, offset, optionValue, (optional) relativeDateOptionValues }
     */
    const handleRelativeDateOnChange = (params) => {
        // resolve relative date option value

        if (!params.optionValue) {
            params.optionValue = params.relativeDateOptionValues ? params.relativeDateOptionValues[0].value : relativeDateOptions[0].value;
        }

        if (!loading) {
            setRelativeDateOptionValue(params.optionValue);
        }

        // resolve relative date offset
        const option = constants.getRelativeDateValueOption(params.optionValue, params.relativeDateOptionValues);

        // if this is a "no date" option, clear the offset
        if (option && constants.isEmpty(option.hours)) {
            params.offset = null;
        }
        // if this is not a "no date" option and the offset does not exist, set the offset to its default value
        else if (constants.isEmpty(params.offset)) {
            params.offset = 0;
        }

        if (!loading) {
            setRelativeDateOffset(params.offset);
        }

        // resolve date value
        // { dateType, offset, optionValue, relativeDateOptionValues, dateTimePart, absoluteDateOption }
        const calcDateValueParams = {
            dateType: params.dateType,
            offset: params.offset,
            optionValue: params.optionValue,
            relativeDateOptionValues: params.relativeDateOptionValues ? params.relativeDateOptionValues : relativeDateOptions,
            dateTimePart: dateTimePart,
            absoluteDateOption: absoluteDateOption,
        };

        const dateValue = constants.calcDateValue(calcDateValueParams);

        if (!loading) {
            setAbsoluteDate(dateValue);
        }

        // invoke onChange callback
        if (onChange) {
            // {
            //     date: value,
            //     dateType: value (absolute (default), relative),
            //     relativeDateOffset: value (0 is default),
            //     relativeDateOption: value (undefined is default)
            // }
            const dateInfo = {
                date: dateValue,
                dateType: params.dateType,
                relativeDateOffset: params.offset,
                relativeDateOptionValue: params.optionValue,
            };

            onChange(dateInfo);
        }
    };

    /**
     * Create Mandatory Indicator Component when the mandatory prop is set.
     * @returns {object} - Mandatory Indicator Component if the mandatory prop is set otherwise null.
     */
    const mandatoryIndicatorComponent = () => {
        if (mandatory) {
            return (
                <div data-testid="mandatory-indicator-test-id" className={classes.mandatoryIndicator}>
                    *
                </div>
            );
        }

        return null;
    };

    /**
     * Create Date Picker Component based on datePickerType (absolute or relative).
     * @returns {object} - Date Picker Component based on datePickerType (absolute or relative).
     */
    const pickerComponent = () => {
        if (datePickerType === constants.dateTypeEnum.absolute) {
            return (
                <Input
                    id="absoluteDate"
                    data-testid={'absolute-date-input-test-id'}
                    className={classes.absoluteEditorContainer}
                    disabled={disabled}
                    readOnly={readOnly}
                    type={absoluteDateOption === constants.absoluteDateOptionEnum.dateAndTime ? 'datetime-local' : 'date'}
                    error={error}
                    value={absoluteInternalDateValue}
                    onChange={(event) => {
                        const isEventDateEmpty = constants.isEmpty(event.target.value);

                        let date;
                        let adjustedDate;
                        // split date and time sections
                        const sections = event.target.value.split('T');
                        // split date parts
                        const dateParts = sections[0].split('-');
                        if (absoluteDateOption === constants.absoluteDateOptionEnum.dateAndTime && !constants.isEmpty(sections[1])) {
                            // split time parts
                            const timeParts = sections[1].split(':');
                            // dateParts[1] ie. month part is 0-indexed
                            date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1]);
                            adjustedDate = date;
                        } else {
                            date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
                            adjustedDate = constants.createAbsoluteDate(date, dateTimePart);
                        }

                        if ((isEventDateEmpty && !allowEmptyDates) || (!isEventDateEmpty && !isValidDate(adjustedDate))) {
                            return;
                        }

                        setAbsoluteInternalDateValue(event.target.value);
                        handleAbsoluteDateOnChange({
                            dateValue: adjustedDate,
                            dateType: datePickerType,
                            offset: relativeDateOffset,
                            optionValue: relativeDateOptionValue,
                        });
                    }}
                />
            );
        } else {
            return (
                <>
                    <div data-testid="relative-editor-container-test-id" className={classes.relativeEditorContainer}>
                        <Input
                            className={classes.numberControl}
                            data-testid="number-integers-only-test-id"
                            id="numberIntegersOnly"
                            error={error && error !== false ? true : false}
                            disabled={disabled}
                            readOnly={readOnly}
                            placeholder={allowEmptyDates === true ? '-' : undefined}
                            type="number"
                            inputAttr={{ min: 0, max: 400 }}
                            integerOnly={true}
                            value={relativeDateOffset}
                            onChange={(event) => {
                                handleRelativeDateOnChange({
                                    dateType: datePickerType,
                                    offset: Number(event.target.value),
                                    optionValue: relativeDateOptionValue,
                                });
                            }}
                        />
                        <Dropdown
                            className={classes.relativeDateDropDownControl}
                            data-testid="relative-date-options-test-id"
                            id="relativeDateOptiondId"
                            name="relativeDateDropdown"
                            error={error && error !== false ? true : false}
                            isClearable={false}
                            isDisabled={disabled}
                            readOnly={readOnly}
                            options={relativeDateOptions}
                            value={relativeDateOptionValue}
                            onChange={(event) => {
                                handleRelativeDateOnChange({
                                    dateType: datePickerType,
                                    offset: relativeDateOffset,
                                    optionValue: event.value,
                                });
                            }}
                            menuPlacement={menuPlacement}
                            menuPosition={menuPosition}
                            closeMenuOnScroll={closeMenuOnScroll}
                        />
                    </div>
                    {processMessages()}
                </>
            );
        }
    };

    /**
     * Render Date Picker Component.
     * @returns {object} - Date Picker Component.
     */
    const renderDatePicker = () => {
        if (loading) return null;

        return (
            <div id={id} data-testid="relative-date-picker-test-id" className={classes.root}>
                {mandatoryIndicatorComponent()}
                <div data-testid="relative-date-picker-test-label-id" className={classes.labelControl}>
                    {label}
                </div>
                <Typography className={classes.dateTypeContainer}>
                    <Link
                        className={optionButtonStyle(constants.dateTypeEnum.absolute)}
                        data-testid="absolute-date-label-test-id"
                        component="button"
                        variant="body2"
                        onClick={() => {
                            if (!readOnly && !disabled) {
                                handleOptionsClick(constants.dateTypeEnum.absolute);
                            }
                        }}
                    >
                        {absoluteLabel}
                    </Link>
                    <Link className={classes.separatorLabel} component="button" onClick={preventDefault} variant="body2">
                        {' | '}
                    </Link>
                    <Link
                        className={optionButtonStyle(constants.dateTypeEnum.relative)}
                        data-testid="relative-date-label-test-id"
                        disabled={disabled}
                        readOnly={readOnly}
                        component="button"
                        variant="body2"
                        onClick={() => {
                            if (!readOnly && !disabled) {
                                handleOptionsClick(constants.dateTypeEnum.relative);
                            }
                        }}
                    >
                        {relativeLabel}
                    </Link>
                </Typography>
                {pickerComponent()}
            </div>
        );
    };

    return <>{renderDatePicker()}</>;
};

RelativeDatePicker.defaultProps = {
    id: 'relative-date-picker',
    disabled: false,
    readOnly: false,
    mandatory: false,
    error: undefined,
    absoluteLabel: 'Absolute',
    relativeLabel: 'Relative',
    dateTimePart: constants.dateTimePartEnum.startOfDay,
    initialDateInfo: {
        date: new Date(),
        dateType: constants.dateTypeEnum.absolute,
        relativeDateOffset: 0,
        relativeDateOptionValue: undefined,
    },
    allowEmptyDates: false,
    menuPlacement: 'auto',
    menuPosition: 'fixed',
    absoluteDateOption: constants.absoluteDateOptionEnum.dateOnly,
};

RelativeDatePicker.propTypes = {
    /** Id of the relative Picker */
    id: PropTypes.string,
    /** Label text */
    label: PropTypes.string,
    /** Absolute Label text */
    absoluteLabel: PropTypes.string,
    /** Relative Label text */
    relativeLabel: PropTypes.string,
    /** true to show indication that a field is mandatory */
    mandatory: PropTypes.bool,
    /** true to show an error indicator or a string to show an error indicator and message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** onChange - returns date value and relative or absolute, relative includes offset and type. */
    onChange: PropTypes.func,
    /** Relative Date Values Options */
    /** { value: 0, label: 'Hours ago', startFromNow: true, hours: 1, typeToFrom: 1 }, */
    relativeDateValueOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            startFromNow: PropTypes.bool.isRequired,
            hours: PropTypes.number.isRequired,
            typeToFrom: PropTypes.oneOf(Object.values(constants.relativeDateDirectionEnum)).isRequired,
        })
    ),
    /**  
        date Info {
          date: value, 
          dateType: value (absolute (default), relative), 
          relativeDateOffset: value (0 is default), 
          relativeDateOption: value (undefined is default) 
        } */
    initialDateInfo: PropTypes.shape({
        date: PropTypes.object,
        dateType: PropTypes.oneOf(Object.values(constants.dateTypeEnum)),
        relativeDateOffset: PropTypes.number,
        relativeDateOptionValue: PropTypes.string,
    }),
    /** if set to true, allow null dates  */
    allowEmptyDates: PropTypes.bool,
    /** Date Time Part - Returns date + time part: startOfDay, endOfDay */
    dateTimePart: PropTypes.oneOf(Object.values(constants.dateTimePartEnum)),
    // Absolute Date Option - Allows/Disallows Time selection for absolute date
    absoluteDateOption: PropTypes.oneOf(Object.values(constants.absoluteDateOptionEnum)),
    /** true to disable the controls */
    disabled: PropTypes.bool,
    /** true to make the controls read only */
    readOnly: PropTypes.bool,
    /** {function} getStringResource - callback for getting localized string */
    getStringResource: PropTypes.func,
    // Set this to 'absolute' if you are working with no scroll containers
    menuPosition: PropTypes.oneOf(menuPositionValues),
    menuPlacement: PropTypes.oneOf(menuPlacementValues),
    // Set this to false if you are working with no scroll containers
    closeMenuOnScroll: PropTypes.oneOf(closeMenuOnScrollValues),
};

RelativeDatePicker.dateTypeEnum = constants.dateTypeEnum;
RelativeDatePicker.relativeDateDirectionEnum = constants.relativeDateDirectionEnum;
RelativeDatePicker.dateTimePartEnum = constants.dateTimePartEnum;
RelativeDatePicker.createAbsoluteDate = constants.createAbsoluteDate;
RelativeDatePicker.calcDateValue = constants.calcDateValue;
RelativeDatePicker.dateOptionEnum = constants.dateOptionEnum;
RelativeDatePicker.getRelativeDateValueOption = constants.getRelativeDateValueOption;
RelativeDatePicker.absoluteDateOptionEnum = constants.absoluteDateOptionEnum;

export default RelativeDatePicker;
