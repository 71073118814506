/**
 * @module webcore-ux/nextgen/components/More/More
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuList, Popup } from '..';
import MoreVert from '../Icons/MoreVert';

const More = ({ menuOptions, size, popupProps, 'data-testid': dataTestId, className, isTitleCase }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickMenuButton = (event) => {
        setAnchorEl(!anchorEl ? event.currentTarget : null);
    };

    const handleClickOption = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-haspopup="true"
                onClick={handleClickMenuButton}
                data-testid={dataTestId}
                className={classNames({ '-active': Boolean(anchorEl), small: size === 'small' }, className)}
            >
                <MoreVert />
            </IconButton>

            {anchorEl && (
                <Popup
                    placement={'bottom-end'}
                    anchorEl={anchorEl}
                    isOpen={Boolean(anchorEl)}
                    onOutsideClick={() => setAnchorEl(null)}
                    disableArrow={true}
                    {...popupProps}
                >
                    <MenuList
                        isTitleCase={isTitleCase}
                        menuOptions={menuOptions}
                        onClick={handleClickOption}
                        data-testid="wcux-nxt-more-menu"
                    />
                </Popup>
            )}
        </>
    );
};

More.defaultProps = {
    size: 'small',
    popupProps: {},
    'data-testid': 'wcux-nxt-more-menu-button',
};

More.propTypes = {
    /** Preset sizes for the button */
    size: PropTypes.oneOf(['small', 'normal']),
    /** Menu option props for individual menu items */
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            /** Optional - The value of the menu item to pass into the click callback. If displayLabel is not supplied, value will be used as the display text */
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            /** Optional - Human-friendly text representation of the value. Eg. value could be a UUID and displayLabel could be a name */
            displayLabel: PropTypes.string,
            /**
             * Callback when this option is clicked
             * Signature: (value: String | Number, index: number) -> undefined
             */
            onClick: PropTypes.func,
            /** Optional - show Icon in menuitem */
            icon: PropTypes.node,
        })
    ),
    /** Props to apply to the Popup element, eg. to control the placement */
    popupProps: PropTypes.shape({
        /**
         * Callback when the popup contents are clicked.
         * Signature: (event) -> void
         */
        onClick: PropTypes.func,
        /**
         * Determines the preferred position of the popup.
         * The Popup will flip positions depending on screen size.
         */
        placement: PropTypes.oneOf([
            'bottom-end',
            'bottom-start',
            'bottom',
            'left-end',
            'left-start',
            'left',
            'right-end',
            'right-start',
            'right',
            'top-end',
            'top-start',
            'top',
        ]),
        /** If true, disables the arrow pointing to the anchor element */
        disableArrow: PropTypes.bool,
    }),
    className: PropTypes.string,
    /** Data test id to be added to the button root */
    'data-testid': PropTypes.string,
    /** Boolean to determine if text from the item value should be title case or not (does not apply to displayLabel) */
    isTitleCase: PropTypes.bool,
};

export default styled(More)`
    ${({ theme }) => `
        &&.-active { // && to override MUI
            color: ${theme.palette.primary.main};
            background: ${theme.palette.action.hover};
        }

        &.small {
            padding: 4px;
        }

        &.rectangle {
            border-radius: 8px;
        }
    `}
`;
