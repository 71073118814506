/**
 * @file Component that displays a text value with a 'Show more' toggle if configured
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ELLIPSIS, SHOW_MORE, SHOW_LESS } from '../Constants';

function TextDisplay({ value, config, getStringResource, className }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const { showMore, showLess, maxLength } = config;
    let text = typeof value === 'number' ? String(value) : value;
    let lineOverflow = maxLength > 0 && text && text.length > maxLength;
    let isTruncated = !isExpanded && lineOverflow;
    if (isTruncated) {
        const index = text.lastIndexOf(' ', maxLength);
        // Truncate at whitespace if found or truncate at maxLength
        text = `${text.substring(0, index > 0 ? index : maxLength)}${ELLIPSIS}`;
    }

    const handleShowMoreTextClick = () => {
        /** The default behviour is to allow the user hide or show the full length of long strings.
         * Toggle to show or hide */
        setIsExpanded((prevState) => !prevState);
    };

    let showMoreText = isTruncated ? showMore || SHOW_MORE : showLess || SHOW_LESS;

    return (
        <span className={className} data-testid="wcux-text-display">
            <span>{text}</span>
            {lineOverflow && (
                <span className="wcux-display-field-more" data-testid="wcux-display-field-more" onClick={handleShowMoreTextClick}>
                    {getStringResource(showMoreText)}
                </span>
            )}
        </span>
    );
}

TextDisplay.defaultProps = {
    getStringResource: (string) => string,
    value: '',
    config: {},
};

TextDisplay.propTypes = {
    /** Display value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Display control configuration */
    config: PropTypes.shape({
        /** The locale path for the 'show more' label. If supplied, the 'show more' toggle will render */
        showMore: PropTypes.string,
        /** The locale path for the 'show less' label. If supplied, the 'show less' toggle will render */
        showLess: PropTypes.string,
        /** Character limit before truncation */
        maxLength: PropTypes.number,
        /** Name of the field for differentiating between fields on a user event*/
        name: PropTypes.string,
    }),
    /** To get the localized string. Signature: (String) -> String */
    getStringResource: PropTypes.func,
    className: PropTypes.string,
};

const StyledTextDisplay = styled(TextDisplay)`
    ${() => `
        .wcux-display-field-more {
            cursor: pointer;
            color: #3366FF; 
            text-decoration: underline;
            margin-left: 0.625em;
        }
    `}
`;

export default StyledTextDisplay;
