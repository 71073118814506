/**
 * @file material ui theme
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 *
 * ===============================================================================================
 * IMPORTANT, PLEASE READ BEFORE EDITING
 * -----------------------------------------------------------------------------------------------
 *
 * The only reason to add styles to this document is:
 *   (1) you want to override the default style provided by the Material-UI framework.
 *   (2) you want to introduce a new concept that Material-UI does not cater for (like Readonly)
 *
 * Where possible try to find the correct default style to use or override before adding a custom
 * style variable or group. When implementing a Webcore NextGen component try and use the smallest
 * amount of custom styling possible.
 *
 * When adding colour constants please name them as close to the UX style guide as possible to
 * make it easier to track how a defined colour maps between the theme and the provided UX style.
 *
 * Any custom theme variables or groups please tag with a comment to make it easier to track.
 *
 * See: https://material-ui.com/customization/default-theme/
 *
 * ===============================================================================================
 */

import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const primaryText = 'rgba(0, 0, 0, 0.87)';

// Directions
export const dirLTR = 'ltr';

let theme = createTheme({
    direction: dirLTR,
    // [Deprecated] Layout is a custom style group for layout specific values, avoid using
    layout: {
        appBar: {
            height: 62,
        },
        tray: {
            openWidth: 384,
            openHeight: 384,
            closeWidth: 80,
        },
    },
    palette: {
        type: 'light',
        // [Deprecated] Custom style group, avoid using
        common: {
            nearWhite: '#F2F2F2',
            nearBlack: '#21252B',
        },
        background: {
            paper: '#FFFFFF',
            // "surface #" are custom styles
            // TODO with the design system update, these are out of order, but many properties are currently in use by applications
            // and cannot be changed without widespread styling impact.
            surface1: '#F2F2F2',
            surface2: '#C2D9E4',
            surface4: '#FFFFFF',
            surface5: '#F8F9F9',
            surface7: '#E5E5E5',
            default: '#E2E7EE',
            // Custom style, avoid using
            readOnly: 'rgba(0, 0, 0, 0.1)',
        },
        // [Deprecated] CardBackground is a custom style group, avoid using this if possible. Consider using "theme.palette.background.*" instead
        cardBackground: {
            main: '#FFFFFF',
        },
        text: {
            primary: primaryText,
            secondary: 'rgba(12, 18, 28, 0.64)',
            disabled: 'rgba(12, 18, 28, 0.42)',
            hint: 'rgba(12, 18, 28, 0.42)',
        },
        divider: 'rgba(12, 18, 28, 0.16)',
        // [Deprecated] BodyText is a custom style group, avoid using these if possible. Consider using or overriding "theme.palette.text.*" instead.
        bodyText: {
            main: '#333333',
        },
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            hover: 'rgba(23, 25, 26, .05)',
            selected: 'rgba(0, 131, 143, 0.12)',
        },
        primary: {
            lightest: '#7EC7CF', // [Deprecated] Custom style.
            light: '#4FB3BF',
            main: '#00848F',
            dark: '#005662',
        },
        secondary: {
            light: '#8E99AC',
            main: '#606B7D',
            dark: '#3F4551',
        },
        // [Deprecated] - Brand is a custom style group.
        brand: {
            light: '#FF5551',
            dark: '#AB0000',
            red: '#C3002F',
            teal: '#6EC9CD',
        },
        error: {
            light: '#EA6767',
            main: '#D01515',
            dark: '#9C0505',
            contrastText: '#FFFFFF',
        },
        // [Deprecated] - Use warning instead of caution.
        caution: {
            light: '#FFDF40',
            main: '#FFBF00',
            contrastText: primaryText,
        },
        info: {
            light: '#64B5F6',
            main: '#2196F3',
            dark: '#1976D2',
            contrastText: '#FFFFFF',
        },
        warning: {
            light: '#FFDF40',
            main: '#FFBF00',
            dark: '#FF9F10',
            contrastText: primaryText,
        },
        success: {
            light: '#81C784',
            main: '#4CAF50',
            dark: '#388E3C',
            contrastText: '#FFFFFF',
        },
        // [Deprecated] Red is a custom style group, avoid using these if possible.
        red: {
            main: '#F44336',
        },
        grey: {
            0: '#000000',
            70: '#464646',
            104: '#686868',
            135: '#889DB5',
            159: '#9F9F9F',
            186: '#BABABA',
            200: '#C8C8C8',
            202: '#CACFD6',
            208: '#D1DBE5',
            225: '#E2E7EE',
            255: '#FFFFFF',
            /** Custom grey colours per design system; used for borders */
            base: 'rgba(12, 18, 28, 1)',
            grey1: 'rgba(12, 18, 28, 0.87)',
            grey2: 'rgba(12, 18, 28, 0.64)',
            grey3: 'rgba(12, 18, 28, 0.42)',
            grey4: 'rgba(12, 18, 28, 0.16)',
            contrast: 'rgba(255, 255, 255, 1)',
        },
        // [Deprecated] NearWhite is a custom style group, avoid using these if possible. This has been moved into "theme.palette.common.*"
        nearWhite: {
            main: '#F2F2F2',
        },
        // [Deprecated] NearBlack is a custom style group, avoid using these if possible. This has been moved into "theme.palette.common.*"
        nearBlack: {
            main: '#21252B',
        },
        // [Deprecated] Custom style group, avoid using these if possible.
        blue: {
            100: '#E3FDFF',
        },
        hyperlink: {
            // These colors have been ported from old components and may not follow new ux guidelines
            main: '#3366FF',
            visited: '#2C20D4',
        },
        sideMenu: {
            background: 'rgba(43, 48, 57, 1)',
        },
    },
    typography: {
        fontFamily: 'Open Sans, Helvetica',
        primary: 'rgba(12, 18, 28, 0.87)',
        secondary: 'rgba(12, 18, 28, 0.64)',
        /** [Deprecated] Custom style, avoid using tertiary */
        tertiary: 'rgba(33, 33, 33, 0.4)',
        body1: {
            fontSize: '16px',
        },
        body2: {
            fontSize: '14px',
        },
        h1: {
            fontSize: '96px',
        },
        h2: {
            fontSize: '60px',
        },
        h3: {
            fontSize: '48px',
        },
        h4: {
            fontSize: '34px',
        },
        h5: {
            fontSize: '24px',
        },
        h6: {
            fontSize: '20px',
        },
        subtitle1: {
            fontSize: '16px',
        },
        subtitle2: {
            fontSize: '14px',
        },
        caption: {
            fontSize: '12px',
        },
        button: {
            fontSize: '16px',
            fontWeight: 600,
        },
        overline: {
            fontSize: '10px',
            letterSpacing: '1.5px',
        },
        /** Custom style group */
        fontSizes: {
            small: '12px',
            medium: '14px',
            large: '16px',
            xlarge: '18px',
        },
        fontWeightSemiBold: 600,
    },
    // In accordance to the design system, Only 8 of 24 elevation levels are used
    shadows: Object.entries({
        0: 'none',
        1: '0px 0px 1px rgba(0, 0, 0, 0.039), 0px 0.5px 1.5px rgba(0, 0, 0, 0.19)',
        2: '0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19)',
        4: '0px 0.5px 1.75px rgba(0, 0, 0, 0.039), 0px 1.85px 6.25px rgba(0, 0, 0, 0.19)',
        6: '0px 2.5px 3px rgba(0, 0, 0, 0.039), 0px 2.75px 9px rgba(0, 0, 0, 0.19)',
        8: '0px 0.5px 5px rgba(0, 0, 0, 0.039), 0px 3.75px 11px rgba(0, 0, 0, 0.19)',
        12: '0px 1px 6px rgba(0, 0, 0, 0.039), 0px 5.5px 16px rgba(0, 0, 0, 0.19)',
        16: '0px 3px 6px rgba(0, 0, 0, 0.039), 0px 7px 24px rgba(0, 0, 0, 0.19)',
        24: '0px 3px 13px rgba(0, 0, 0, 0.039), 0px 10.5px 36px rgba(0, 0, 0, 0.19)',
    }).reduce((acc, [key, value]) => {
        acc[parseInt(key)] = value;
        return acc;
    }, []),
    breakpoints: {
        // Ensure all values from the MUI default theme are listed or MUI may calculate NaN values and cause a crash
        values: {
            xs: 0,
            sm: 375,
            md: 600,
            lg: 960,
            xl: 1280,
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
