/**
 * @file Action Buttons
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { IconButton as MuiIconButton } from '@material-ui/core';

import { Button } from '..';
import More from '../More/More';
import { stringToTitleCase } from '../../../Utils';

// JSS styling for this component
const useStyles = makeStyles((theme) => ({
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginRight: `${theme.spacing(1)}px`,
        },
    },
    moreButton: {
        padding: 0,
        height: '40px',
        paddingLeft: `${theme.spacing(1)}px`,
        paddingRight: `${theme.spacing(1)}px`,
    },
}));

export const ELEMENT_WIDTHS = {
    button: 150,
    icon: 55,
    padding: 16,
};

const ActionButtons = ({ actions = [] }) => {
    const classes = useStyles();
    const actionRef = useRef();

    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth);
        };

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [width]);

    const renderButtons = (actions) => {
        return actions.map((button, index) => (
            <div key={`action-buttons-${index}`}>
                <Button
                    key={'button' + index}
                    variant={index === 0 ? 'primary' : 'secondary'}
                    {...{ onClick: button.onClick }}
                    data-testid={`wcux-nxt-action-button-${index}`}
                    disabled={button.disabled}
                >
                    {stringToTitleCase(button.value)}
                </Button>
            </div>
        ));
    };

    const renderIcons = (actions) => {
        return actions.map((icon, index) => (
            <div key={`action-icons-${index}`}>
                <MuiIconButton
                    onClick={icon.onClick}
                    key={'icon' + index}
                    disabled={icon.disabled}
                    data-testid={`wcux-nxt-action-icon-${index}`}
                >
                    {icon.icon}
                </MuiIconButton>
            </div>
        ));
    };

    const renderMore = (actions) =>
        actions.length > 0 && (
            <More
                menuOptions={actions}
                isTitleCase={true}
                data-testid="wcux-nxt-actions-more-btn"
                className={classes.moreButton}
                size="normal"
            />
        );

    const renderActionButtonComponents = () => {
        const actionButtons = actions.filter((action) => action.icon === undefined);
        const actionIcons = actions.filter((action) => action.icon);
        const iconWidth = (ELEMENT_WIDTHS.icon + ELEMENT_WIDTHS.padding) * actionIcons.length;
        const buttonWidth = (ELEMENT_WIDTHS.button + ELEMENT_WIDTHS.padding) * actionButtons.length;

        let moreActions = [];
        const actionWidth = (actionRef.current && actionRef.current.offsetWidth) || width;
        const differenceInWidth = iconWidth + buttonWidth - actionWidth;

        if (iconWidth + buttonWidth > actionWidth) {
            let actionButtonsInKebabMenu = Math.ceil(differenceInWidth / ELEMENT_WIDTHS.button) || 0;
            if (actionButtonsInKebabMenu > actionButtons.length) actionButtonsInKebabMenu = actionButtons.length;

            moreActions = actionButtons.splice(actionButtons.length - actionButtonsInKebabMenu);
        }

        const iconWidthDifference = iconWidth - actionWidth;

        if (iconWidth > actionWidth) {
            let iconButtonsInKebabMenu = Math.ceil(iconWidthDifference / ELEMENT_WIDTHS.icon) || 0;
            if (iconButtonsInKebabMenu > actionIcons.length) iconButtonsInKebabMenu = actionIcons.length;

            moreActions = moreActions.concat(actionIcons.splice(actionIcons.length - iconButtonsInKebabMenu));
        }

        return (
            <>
                {renderIcons(actionIcons)}
                {renderButtons(actionButtons)}
                {renderMore(moreActions)}
            </>
        );
    };

    return (
        <div ref={actionRef} className={classNames('wcux-nxt-action-buttons', classes.actionButtons)} data-testid="wcux-nxt-action-buttons">
            {renderActionButtonComponents()}
        </div>
    );
};

ActionButtons.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            /** Value of the string to display */
            value: PropTypes.string,
            /** click function to fire when button is pressed */
            onClick: PropTypes.func,
            /** Shows Icon when provided. Currently shows all icons on bar except at smallest breakpoint
             where it is in the kebab menu */
            icon: PropTypes.node,
            /** Boolean to disable the button */
            disabled: PropTypes.bool,
        })
    ),
};

export default ActionButtons;
