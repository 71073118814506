/**
 * @module webcore-ux/nextgen/components/DataGrid
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTable from 'react-table';
import { ReactTableDefaults } from 'react-table';
import 'react-table/react-table.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { renderTristateOrBoolean } from '../../../tableColumnUtils';
import Handlebars from 'handlebars';
import { SelectDataGrid } from './SelectDataGrid';
import { EditableCell } from './EditableCell';
import { getHeight, ROW_SPACING_HEIGHT_MAP, STATUSES } from './constants';
import ErrorCircle from '../Icons/ErrorCircle';
import { showNumber, showDuration, showDateTime } from 'webcore-common/LocaleUtils';
import { getValueFromObj } from 'webcore-common/GenericDataUtils';
import Tooltip from '../Tooltip/Tooltip';
import Chip from '../Chip/Chip';
import { registerAllHelpers } from 'webcore-common/HandlebarsCustomHelper';
import { Pagination } from '..';
import Logger from 'abb-webcore-logger/Logger';
import { styles, globalStyles } from './styles.js';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    ${globalStyles}
`;

// register all handlerbars custom helpers
registerAllHelpers(Handlebars);

class DataGrid extends React.Component {
    constructor(props) {
        super(props);

        let totalItems = props.totalItems || props.data.length;
        // Ref for storing the scroll positions
        this.tableRef = React.createRef();
        this.state = {
            columnOrder: DataGrid.getOrderingMap(props.columns),
            page: this.props.page || 1,
            totalItems,
            totalPages: Math.ceil(totalItems / props.itemsPerPage),
            itemsPerPage: props.itemsPerPage,
            itemsPerPageOptions: props.itemsPerPageOptions,
            siblingCount: props.pageSiblingCount,
        };
    }

    render() {
        const {
            className,
            minRows,
            columnOrder,
            canRearrangeColumns,
            onColumnsRearranged,
            columns,
            onClick,
            onDoubleClick,
            dir,
            rowSelectOptions,
            rowSpacing,
            'data-testid': dataTestId,
            paginationLocales,
            manual,
            simplifiedPagination,
            disabledRows,
            statusRows,
            ...other
        } = this.props;

        const isRTL = dir === 'rtl';

        /**
         * minRows = null: allows the user to access ReactTable's default unset (undefined) minRows behaviour.
         */
        const rows = minRows === null ? undefined : minRows;

        const order = columnOrder ? columnOrder : this.state.columnOrder;
        const columnsCopy = columns.slice(); // Do not mutate the original when sorting
        columnsCopy.sort((a, b) => {
            const aSort = order[a.id] || 0;
            const bSort = order[b.id] || 0;
            return isRTL ? bSort - aSort : aSort - bSort;
        });

        columnsCopy.forEach((column) => {
            // If a cell renderer is not defined then use the custom cell renderer
            if (column.columnType && !column.Cell) {
                switch (column.columnType) {
                    case 'string':
                        column.Cell = ({ value }) => this.wrapText(value);

                        break;

                    case 'duration':
                        column.Cell = (props) => this.wrapText(showDuration(props.value, column.format, column.source, column.precision));

                        break;

                    case 'number':
                        column.Cell = (props) => this.wrapText(showNumber(props.value, column.precision));

                        break;

                    case 'tristate':
                    case 'boolean':
                        column.Cell = (props) => {
                            return renderTristateOrBoolean(column.columnType, props.value, column.useToggleSwitch);
                        };

                        break;

                    case 'datetime':
                    case 'date':
                    case 'time':
                        column.Cell = (props) => this.renderDatetime(column, props);

                        break;

                    case 'hyperlink':
                        column.Cell = (props) => this.wrapText(this.renderHyperlink(column, props));

                        break;

                    case 'listitem':
                        column.Cell = (props) => this.wrapText(this.renderListItem(column, props));

                        break;

                    case 'reference':
                        column.Cell = (props) => this.wrapText(this.referenceCellRenderer(column, props));

                        break;

                    case 'error':
                        column.Cell = (props) => this.renderErrorMessageCell(props);
                        break;

                    case 'arrayList': // List of Status indicators (aka material-ui Chip)
                        column.Cell = (props) => this.renderArrayListCell(column, props);
                        column.sortable = false;
                        break;

                    case 'node':
                        column.sortable = false;
                        if (typeof column.onRender !== 'function') {
                            Logger.error('columnType: node, requires an onRender field of type function');
                            column.Cell = null;
                        } else {
                            column.Cell = (props) => column.onRender(column, props);
                        }

                        break;

                    default:
                        throw new Error(`Invalid DataGrid columnType: ${column.columnType}`);
                }
            }

            // Requried fot tdProps to get spacing height
            column.rowSpacing = rowSpacing;
        });

        /**
         * Callback when the user has finished dragging a column header.
         * If the drag results in a different column ordering, the columns are rearranged.
         * @param {object} result: The onDragEnd result object from react-beautiful-dnd. Contains dragged item source and destination.
         */
        const onDragEnd = (result) => {
            const { source, destination } = result;
            if (canRearrangeColumns && destination && source.index !== destination.index) {
                const [removed] = columnsCopy.splice(source.index, 1);
                columnsCopy.splice(destination.index, 0, removed);
                const orderingMap = DataGrid.getOrderingMap(columnsCopy, isRTL);
                this.setState({
                    columnOrder: orderingMap,
                });
                if (onColumnsRearranged) {
                    onColumnsRearranged(orderingMap);
                }
            }
        };

        /**
         * @param {object} state: The table state data.
         * @returns {JSX.Element}
         * This structure addresses the following:
         * 1) Droppable must be the scrollable container (`table`) or a child for autoscroll to work.
         * 2) Droppable zone wraps `thead` and `tbody` to allow drop on columns (not just thead area). Also, if
         * Droppable only wraps `thead`, overflowed `th`s aren't valid drop zones.
         */
        const tableComponent = (state) => {
            const defaultTable = ReactTableDefaults.TableComponent(state);
            if (!canRearrangeColumns) {
                return (
                    <div {...defaultTable.props} ref={this.tableRef}>
                        {state.children}
                    </div>
                );
            }

            // Hack: state.children is an array<object|null>. The first valid element is the header object.
            // This is to apply the internally-calculated minWidth style onto the droppable wrapper,
            // otherwise overflowed elements get cut off during drag and drop.
            const header = state.children.find((child) => child !== null);

            // Speed up the drop animation to make the column swap feel more responsive.
            function cloneStyle(style, snapshot) {
                if (!snapshot.isDropAnimating) {
                    return style;
                }

                return {
                    ...style,
                    transitionDuration: '0.1s',
                };
            }

            return (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="1"
                        direction="horizontal"
                        renderClone={(provided, snapshot, rubric) => (
                            <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className="wcux-nxt-data-grid-header-clone"
                                style={cloneStyle(provided.draggableProps.style, snapshot)}
                                dir={dir}
                            >
                                {columnsCopy[rubric.source.index].Header}
                            </div>
                        )}
                    >
                        {(provided) => {
                            return (
                                <div {...defaultTable.props} ref={this.tableRef}>
                                    <div
                                        style={header.props.style}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {state.children}
                                        {/* Drop placeholder isn't wanted, but react-beautiful-dnd complains if it isn't included. */}
                                        <div style={{ display: 'none' }}>{provided.placeholder}</div>
                                    </div>
                                </div>
                            );
                        }}
                    </Droppable>
                </DragDropContext>
            );
        };

        /**
         * @param {object} state: The th state data.
         * @returns {JSX.Element}
         * If column rearrangement is enabled, then this returns a modified version of the th allowing it to be dragged.
         */
        const thComponent = (state) => {
            /**
             * @see thProps for where id, index came from
             */
            const { id, index, ...defaults } = state;
            const defaultTh = ReactTableDefaults.ThComponent(defaults);
            if (!canRearrangeColumns) {
                return defaultTh;
            }

            const [content, resizeHandle] = defaultTh.props.children;

            const handleMouseDown = (e) => {
                /**
                 * HACK: For editable cells, blur is required for the value change callback to be invoked. But Draggable
                 * blocks this event from occurring, which causes column header interactions to 'reset' the input value.
                 * Focus the Draggable will blur any cell that has focus, so that value changes can be saved as usual.
                 * @see EditableCell.js
                 */
                e.target.focus();
            };

            return (
                <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                        <div {...defaultTh.props} onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}>
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} {...content.props}>
                                {content.props.children}
                            </div>
                            {resizeHandle}
                        </div>
                    )}
                </Draggable>
            );
        };

        /**
         * This creates an ordering map from the sorted columns, allowing thProps easy look-up of the column index required for Draggable.
         * columnOrder isn't used here since it can't be guaranteed that the sorted results match that object.
         * @see thProps
         */
        const postSortOrdering = DataGrid.getOrderingMap(columnsCopy);

        /**
         * thComponent has no reliable way of knowing what the column id or its index is, which are needed for drag and drop.
         * Customizing the props will allow thComponent to receive these properties in the argument.
         *
         * @param {object} tableState:
         * @param {object} row:
         * @param {object} column: The column data.
         * @returns {object}: Custom props object.
         */
        const thProps = (tableState, row, column) => {
            let testId = `column-header-${column.id}`;
            if (dataTestId) testId = dataTestId + '-' + testId;

            return {
                id: column.id,
                index: postSortOrdering[column.id],
                'data-testid': testId,
            };
        };

        /**
         * A row can display a status indicator if:
         * 1) It contains an editable cell with a status applied
         * 2) It contains an error message display cell that has a value
         */
        const statusCallbacks = {};
        for (const status of Object.values(STATUSES)) {
            statusCallbacks[status] = [];
            columns.forEach((column) => {
                const callbackMap = {
                    [STATUSES.error]: column.getError,
                    [STATUSES.warning]: column.getWarning,
                    [STATUSES.success]: column.getSuccess,
                    [STATUSES.info]: column.getInfo,
                };

                const callback = callbackMap[status];
                if (callback) {
                    statusCallbacks[status].push((row) => callback({ row, column, value: row.original[column.id] }));
                }

                if (status === STATUSES.error && column.columnType === 'error') {
                    statusCallbacks[status].push((row) => Boolean(row.original[column.id]));
                }
            });
        }

        /**
         * Gets the status of the row so it can be displayed. Eg. if the row contains an error cell, this returns 'error'.
         * @param {object} row - Row data
         * @returns {string} - The status, eg. 'error', 'warning'
         */
        const getStatus = (row) => {
            // Returns the status if there are any status associated to the row
            if (statusRows && statusRows.length > 0) {
                let rowStatus = '';
                statusRows.some(({ keyField, value, status }) => {
                    if (row.original[keyField] === value) {
                        if (STATUSES[status]) {
                            rowStatus = status;
                            return true;
                        }
                    }
                });

                return rowStatus;
            }

            // Follow the entries order of STATUSES const
            return Object.values(STATUSES).find((status) => {
                return statusCallbacks[status].some((callback) => callback(row));
            });
        };

        /**
         * Props for Tbody rows
         * @param {object} tableState - table state
         * @param {object} row - row data
         * @returns {object} - Custom props object
         */
        const trProps = (tableState, row) => {
            if (row) {
                let testId = `row-${row.index}`;
                if (dataTestId) testId = dataTestId + '-' + testId;

                return {
                    status: getStatus(row),
                    'data-testid': testId,
                };
            }

            // React Table "padding" element does not have row data
            return {};
        };

        /**
         * Customize the table row to have a lefthand status indicator colour bar. This indicator only rerenders after the
         * dataset has changed, not as the user is editing a cell, due to the onBlur workaround in EditableCell.
         * @see EditableCell
         * @returns {JSX.Element} - Table row JSX
         */
        const trComponent = ({ status, ...defaults }) => {
            const defaultTr = ReactTableDefaults.TrComponent(defaults);

            return (
                <div {...defaultTr.props}>
                    <div className="wcux-nxt-datagrid-row-status-indicator-container">
                        <div className="wcux-nxt-datagrid-cell-backdrop" />
                        <div className="wcux-nxt-datagrid-cell-backdrop-highlight" />
                        <div
                            className={classNames('wcux-nxt-datagrid-row-status-indicator', {
                                [`wcux-nxt-datagrid-row-${status}-indicator`]: status,
                            })}
                        />
                    </div>
                    {defaultTr.props.children}
                </div>
            );
        };

        /**
         * Custom props for table cells
         * @param {object} tableState - table state
         * @param {object} row - row data
         * @param {object} column - column data
         * @returns {object} - Custom props object
         */
        const tdProps = (tableState, row, column) => {
            if (row) {
                let testId = `cell-col${column.id}-row${row.index}`;
                if (dataTestId) testId = dataTestId + '-' + testId;

                return {
                    'data-testid': testId,
                    className: classNames('wcux-nxt-datagrid-cell', `wcux-nxt-datagrid-${column.columnType}-cell`, {
                        'is-editable': column.onChange,
                    }),
                    style: {
                        height:
                            column.columnType === 'error' ? '' : (rowSpacing ? getHeight(rowSpacing) : getHeight(column.rowSpacing)) + 'px',
                    },
                    onClick: () => {
                        if (typeof onClick === 'function') {
                            onClick(row, column);
                        }
                    },
                    onDoubleClick: () => {
                        if (typeof onDoubleClick === 'function') {
                            onDoubleClick(row, column);
                        }
                    },
                };
            }

            return {};
        };

        // Right-to-left hacks: Column rearrangement breaks with dir=rtl because it relies on JS-generated item positions.
        // 1) Set dir=ltr on thead and tbody to accommodate RBDnD's item positions.
        // 2) To display RTL afterward, reverse the column order, and set dir=rtl in children (th and td).
        let rtlProps = {};
        if (isRTL) {
            rtlProps = {
                getTheadProps: () => ({ dir: 'ltr' }),
                getTbodyProps: () => ({ dir: 'ltr' }),
                getTdProps: (tableState, row, col) => ({
                    ...tdProps(tableState, row, col),
                    dir: 'rtl',
                }),
                // Overrides thProps above
                getTheadThProps: (tableState, row, col) => ({
                    ...thProps(tableState, row, col),
                    dir: 'rtl',
                }),
                dir,
            };
        }

        const onChangePage = (page) => {
            this.setState({ page });
            if (typeof this.props.onChangePage === 'function') {
                this.props.onChangePage(page);
            }
        };

        const onChangeItemsPerPage = (itemsPerPage) => {
            const currentTotalItems = this.props.totalItems || this.state.totalItems;

            this.setState({
                page: 1,
                itemsPerPage: itemsPerPage,
                totalPages: Math.ceil(currentTotalItems / itemsPerPage),
            });

            if (typeof this.props.onChangeItemsPerPage === 'function') {
                this.props.onChangeItemsPerPage(1, itemsPerPage);
            }
        };

        const paginationComponent = () => {
            const { itemsPerPageOptions } = this.state;
            const { page, itemsPerPage, totalItems } = this.props;

            const currentItemsPerPage = itemsPerPage || this.state.itemsPerPage;
            const currentTotalItems = totalItems || this.state.totalItems;
            // Total pages should be 1 when total items to display are 0
            const totalPages = currentTotalItems > 0 ? Math.ceil(currentTotalItems / currentItemsPerPage) : 1;
            const currentPage = page || this.state.page;

            return (
                <div className="wcux-nxt-pagination-container">
                    <Pagination
                        data-testid={dataTestId}
                        currentPage={currentPage}
                        onChangePage={onChangePage}
                        itemsPerPage={currentItemsPerPage}
                        totalPages={totalPages}
                        totalItems={currentTotalItems}
                        itemsPerPageOptions={itemsPerPageOptions}
                        onChangeItemsPerPage={onChangeItemsPerPage}
                        className={className}
                        locales={paginationLocales}
                        simplifiedPagination={simplifiedPagination}
                    />
                </div>
            );
        };

        const Table = rowSelectOptions ? SelectDataGrid : ReactTable;

        return (
            <div
                className={classNames('wcux-nxt-data-grid', className, {
                    'wcux-nxt-data-grid-no-empty-rows': rows === 0,
                    'wcux-nxt-data-grid-draggable-columns': canRearrangeColumns,
                })}
                data-testid={dataTestId}
            >
                <GlobalStyle />
                <Table
                    // -highlight is for highlighting the row when hovering or selecting
                    // see https://www.npmjs.com/package/react-table#styles
                    PaginationComponent={paginationComponent}
                    page={(this.props.page || this.state.page) - 1}
                    pageSize={this.state.itemsPerPage}
                    className={`-highlight `}
                    columns={columnsCopy}
                    minRows={rows}
                    TableComponent={tableComponent}
                    ThComponent={thComponent}
                    TrComponent={trComponent}
                    getTheadThProps={thProps}
                    getTrProps={trProps}
                    getTdProps={tdProps}
                    rowSpacing={rowSpacing}
                    manual={manual}
                    disabledRows={disabledRows}
                    {...rtlProps}
                    {...(rowSelectOptions || {})}
                    {...other}
                />
            </div>
        );
    }

    /**
     * Conditionally capture the table's scroll position.
     * 1) If columns have been rearranged or sorted, the scroll position should persist during rerender.
     * 2) If a new set of data has been loaded, or the display direction has changed, the scroll positions should be reset.
     * Determined by whether the prop columns intersect the columns in the columnOrder cache.
     * @see getDerivedStateFromProps
     *
     * @param {object} prevProps: The previous props object.
     * @param {object} prevState: The previous state object.
     * @returns {object|null}: The snapshot object passed into componentDidUpdate.
     */
    getSnapshotBeforeUpdate({ dir }, { columnOrder }) {
        const prevColumnIds = Object.keys(this.state.columnOrder);
        if (
            prevColumnIds.length !== Object.keys(columnOrder).length ||
            prevColumnIds.some((colId) => columnOrder[colId] === undefined) ||
            this.props.dir !== dir
        ) {
            return null;
        }

        const table = this.tableRef.current;
        return {
            scrollLeft: table.scrollLeft,
            scrollTop: table.scrollTop,
        };
    }

    /**
     * Restores the table scroll positions if getSnapshotBeforeUpdate returns a valid object.
     * @param {object} prevProps:
     * @param {object} prevState:
     * @param {object} snapshot: The return value of getSnapshotBeforeUpdate.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const table = this.tableRef.current;
        if (snapshot) {
            table.scrollLeft = snapshot.scrollLeft;
            table.scrollTop = snapshot.scrollTop;
        }

        const { data, itemsPerPage } = this.props;
        if (prevProps.data.length !== data.length) {
            this.setState({
                page: this.props.page || 1,
                totalItems: data.length,
                totalPages: Math.ceil(data.length / itemsPerPage),
                itemsPerPage,
            });
        }
    }

    /**
     * Whether to update the internal columnOrder cache based on props.
     * 1) If an external columnOrder has been passed in, always use it.
     * 2) If columns membership doesn't match the internal columnOrder, assume a new set of data has been passed in, and
     * generate a new order based on those columns.
     *
     * @param {object} props: The incoming props to compare to
     * @param {object} state: The state to compare to
     * @returns {object|null}: The new state, or null.
     */
    static getDerivedStateFromProps(props, state) {
        if (props.columnOrder) {
            return {
                columnOrder: props.columnOrder,
            };
        }

        if (
            props.columns.length !== Object.keys(state.columnOrder).length ||
            props.columns.some((col) => state.columnOrder[col.id] === undefined)
        ) {
            return {
                columnOrder: DataGrid.getOrderingMap(props.columns),
            };
        }

        return null;
    }

    /**
     * Convert a column data array to a flattened ordering map object for storage.
     *
     * @param {object[]} columns: The columns data array
     * @param {boolean} shouldReverse : Whether to reverse the order, eg. to handle RTL ordering.
     * @returns {object}: {columnId: index}
     */
    static getOrderingMap(columns, shouldReverse = false) {
        const newOrdering = {};
        const lastColumn = columns.length - 1;
        columns.forEach((col, i) => {
            newOrdering[col.id] = shouldReverse ? lastColumn - i : i;
        });
        return newOrdering;
    }

    /**
     * Wrap the text of a cell in a span with particular styles applied (eg. to truncate text with ellipsis)
     * @param {string} value - value to display
     * @returns {JSX.element} - Wrapped value
     */
    wrapText(value) {
        return <span className="wcux-nxt-datagrid-text-wrapper">{value}</span>;
    }

    /**
     * Reference cell renderer
     *
     * @param {object} column - column config
     * @param {object} props - render props
     * @returns {string} reference display data
     */
    referenceCellRenderer(column, props) {
        const { value } = props;
        const getStringResource = column.getStringResource || ((key) => key);

        if (column.properties && column.properties.displayAs === 'hyperlink') {
            const urlTemplate = Handlebars.compile(column.properties.urlTemplate);
            const labelTemplate = Handlebars.compile(column.properties.labelTemplate);

            // display object as JSON in new or same window
            return (
                <a
                    href={urlTemplate(props.original)}
                    target={column.properties.openInNewTab ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                >
                    {getStringResource(labelTemplate(props.original))}
                </a>
            );
        }

        if (column.properties && column.properties.displayAs === 'boolean') {
            //display existence checkmark
            return renderTristateOrBoolean(column.properties.displayAs, Boolean(value), column.useToggleSwitch);
        }

        return '';
    }

    /**
     * Get the display for a hyperlink field
     *
     * @param {object} column - column config
     * @param {object} props - render props
     * @returns {string} Hyperlink display
     */
    renderHyperlink(column, props) {
        let value = props.value;

        if (typeof value === 'string') {
            let textToDisplay;

            if (Object.prototype.hasOwnProperty.call(column, 'onClick') && typeof column.onClick === 'function') {
                return (
                    <a
                        href="#"
                        onClick={(event) => {
                            event.preventDefault();
                            column.onClick(props);
                        }}
                    >
                        {props.value}
                    </a>
                );
            }

            if (column.urlTemplate) {
                const templateLink = column.urlTemplate.replace('{cellData}', props.value);
                return (
                    <a href={templateLink} target="_blank" rel="noopener noreferrer">
                        {props.value}{' '}
                    </a>
                );
            }

            if (column.hyperlinkDisplayTextField) {
                textToDisplay = props.original[column.hyperlinkDisplayTextField];
            }

            if (!textToDisplay) {
                textToDisplay = value;
            }

            if (value) {
                return (
                    <a href={value} target="_blank" rel="noopener noreferrer">
                        {textToDisplay}
                    </a>
                );
            }

            if (textToDisplay) {
                return textToDisplay;
            }
        }

        return '';
    }

    /**
     * Get the display for a list item field
     *
     * @param {object} column - column config
     * @param {object} props - render props
     * @returns {string} List item display
     */
    renderListItem(column, props) {
        const value = props.value;
        const getStringResource = column.getStringResource ? column.getStringResource : (key) => key;

        if (value === undefined || value === null || value === '' || typeof value === 'object') {
            return '';
        } else if (column.data) {
            if (column.data.type === 'enum') {
                if (column.data.labelKeyTemplate) {
                    const labelKeyTemplate = Handlebars.compile(column.data.labelKeyTemplate);
                    return getStringResource(labelKeyTemplate(props.original));
                }
            } else if (column.data.type === 'inline') {
                if (Array.isArray(column.data.inlineData) && column.data.inlineData.length > 0) {
                    for (let i = 0; i < column.data.inlineData.length; i += 1) {
                        let item = column.data.inlineData[i];

                        if (item.value === value) {
                            return getStringResource(item.label);
                        }
                    }
                } else {
                    throw new Error(`Inline data was not provided`);
                }
            }

            return value;
        } else {
            return getStringResource(value);
        }
    }

    /**
     * Get the display for a date, time, or datetime field
     *
     * @param {object} column - column config
     * @param {object} props - render props
     * @returns {string|JSX.Element} Datetime display. If editable, returns a MUI input
     */
    renderDatetime(column, props) {
        const { onChange, columnType } = column;

        if (!onChange) {
            return this.wrapText(showDateTime(columnType, props.value));
        }

        // The equivalent of column type 'datetime' for input type is 'datetime-local'
        const type = columnType === 'datetime' ? 'datetime-local' : columnType;
        // Passing props as the row because it contains all the row data already; props.row has a different interface
        return <EditableCell type={type} column={column} row={props} value={props.value} />;
    }

    /**
     * Get the display for error message(s) (this is for displaying issues with the row, not to be confused with editable error status cells)
     * @param {object} props - render props
     * @return {JSX.Element|null} - error message JSX
     */
    renderErrorMessageCell(props) {
        const contents = Array.isArray(props.value) ? props.value.join('\n') : props.value;
        if (!contents) {
            return null;
        }

        return (
            <div>
                <ErrorCircle className={'wcux-nxt-datagrid-error-message-cell-icon'} fontSize="small" color="error" />
                <span className={'wcux-nxt-datagrid-error-message'}>{contents}</span>
            </div>
        );
    }

    /**
     * Get the display for values from an array
     * @param {object} column - column config
     * @param {object} props - render props
     * @return {JSX.Element|null} - rendering the values as a row of labels, assuming the values can be converted into strings directly
     */
    renderArrayListCell(column, props) {
        let { original } = props;

        let objectPath;
        let objectDataType = 'string';
        let itemType = 'object';
        let separator = ', ';

        if (column.arrayList) {
            objectPath = column.arrayList.objectPath;
            objectDataType = column.arrayList.objectDataType;
            itemType = column.arrayList.itemType;
            separator = column.arrayList.separator;
        }

        if (!original || !column.accessor || !objectPath) return null;

        if (objectDataType !== 'string' || itemType !== 'object') return null;

        let arrayValue = getValueFromObj(original, column.accessor, []);
        let values = [];

        if (Array.isArray(arrayValue)) {
            arrayValue.forEach((childObj) => {
                if (typeof childObj === 'object') values.push(getValueFromObj(childObj, objectPath, null));
            });
        }

        if (values.length > 0) {
            return (
                <Tooltip title={values.join(separator)} placement={'auto'}>
                    <div>
                        {values &&
                            values.map((labelValue, i) => (
                                <Chip
                                    color="default"
                                    size="small"
                                    label={labelValue}
                                    key={'wcux-nxt-datagrid-arraylist-value' + i}
                                    className={'wcux-nxt-datagrid-arraylist-value'}
                                />
                            ))}
                    </div>
                </Tooltip>
            );
        }

        return null;
    }
}

DataGrid.defaultProps = {
    minRows: 0,
    canRearrangeColumns: false,
    dir: 'ltr',
    itemsPerPageOptions: [
        {
            value: 10,
        },
        {
            value: 20,
        },
        {
            value: 50,
        },
        {
            value: 100,
        },
    ],
    pageSiblingCount: 1,
    itemsPerPage: 20,
    'data-testid': 'datagrid-root',
    manual: false,
};

DataGrid.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** Data for table to handle */
    data: PropTypes.arrayOf(
        /* Columns and data. ex: { col1: 'string1', col2: 123 } */
        PropTypes.object
    ),
    /** Defines the grid columns. For a full list of properties see https://www.npmjs.com/package/react-table#columns */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * The column ID, which should be unique. This is used to maintain an internal column membership which determines whether the table scroll positions are
             * reset on render.
             */
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            /**
             * Column type. May affect how cells are rendered, i.e. display numbers and dates in the current locale,
             * display booleans as checkboxes, and display values as hyperlinks.
             */
            columnType: PropTypes.oneOf([
                'string',
                'number',
                'duration',
                'boolean',
                'datetime',
                'date',
                'time',
                'hyperlink',
                'listitem',
                'tristate',
                'error',
                'arrayList',
                'reference',
                'node',
            ]),
            /** Field containing the text to display for the hyperlink. Valid for columnType 'hyperlink'. */
            hyperlinkDisplayTextField: PropTypes.string,
            /**
             * If supplied, this enables inline editing of the cells in the column.
             * Signature: ({ row: object, column: object, value: any }) -> undefined
             */
            onChange: PropTypes.func,
            /**
             * Checks if a field has an error. If the provided function returns a string (error message), DataGrid displays
             * error styling on the cell and adds a tooltip with the message.
             * If there are multiple statuses, the order of precedence is: Error > Warning > Success > Info
             * Signature: ({ row: object, column: object, value: any }) -> string
             */
            getError: PropTypes.func,
            /**
             * Same as getError, but a warning instead.
             * Signature: ({ row: object, column: object, value: any }) -> string
             */
            getWarning: PropTypes.func,
            /**
             * Same as getError, but a success message instead.
             * Signature: ({ row: object, column: object, value: any }) -> string
             */
            getSuccess: PropTypes.func,
            /**
             * Same as getError, but an info message instead.
             * Signature: ({ row: object, column: object, value: any }) -> string
             */
            getInfo: PropTypes.func,
        })
    ).isRequired,
    /**
     * The minimum number of rows to be displayed by the table. If 0 (default), the table only displays as many rows
     * as there is data.
     */
    minRows: PropTypes.number,
    /**
     * A ordering map object {columnId: index} for DataGrid to sort columns by. Has the same
     * shape as the object passed into onColumnsRearranged. If not supplied, this is managed internally.
     */
    columnOrder: PropTypes.object,
    /**
     * After dragging, this callback receives an object {columnId: index} of the new ordering.
     * Be sure to set canRearrangeColumns to true if using this.
     */
    onColumnsRearranged: PropTypes.func,
    /** Set true to enable column rearrangement. */
    canRearrangeColumns: PropTypes.bool,
    /** Handler to catch click event on row/column */
    onClick: PropTypes.func,
    /** Handler to catch double click event on row/column */
    onDoubleClick: PropTypes.func,
    /** Display direction. If displaying right to left, 'rtl' must be provided for DataGrid's column rearrangement to work properly */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    /**
     * Options for selecting rows on the DataGrid. If supplied, a column containing checkboxes to select the rows will
     * be displayed, and selection controls will be enabled. The selection state is controlled only, so both selectedRows and
     * onSelectionChanged must be provided for correct functionality.
     */
    rowSelectOptions: PropTypes.shape({
        /** An object shaped {[rowID]: true} where truthy entries determine the selected rows */
        selectedRows: PropTypes.object.isRequired,
        /** Signature: (selectedRows) => void, where selectedRows has the same shape as the selectedRows prop */
        onSelectionChanged: PropTypes.func.isRequired,
        /** The column ID whose data is used as the unique row ID */
        keyField: PropTypes.string.isRequired,
    }),
    /** Row sizing */
    rowSpacing: PropTypes.oneOf(Object.keys(ROW_SPACING_HEIGHT_MAP)),
    // Pagination props
    /** The selected number of items per page. Used to calculate current item display range, and highlights the selection in the menu. */
    itemsPerPage: PropTypes.number,
    /** The list of options to display in the items per page menu */
    itemsPerPageOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            displayLabel: PropTypes.string,
        })
    ),
    /** How many pages to display either side of the current page */
    pageSiblingCount: PropTypes.number,
    /** datatest-id automation of the table */
    'data-testid': PropTypes.string,
    /**
     * Callback function that can be called when the page is changed
     * @param {Number} page - the new page number (page numbers start from 1)
     */
    onChangePage: PropTypes.func,
    /**
     * Callback function that can be called when the items per page change
     * @param {Number} page - The page number always resets to 1
     * @param {Number} itemsPerPage - The new value of the items per page
     */
    onChangeItemsPerPage: PropTypes.func,
    /** Localizable label text for the pagination component. For any of the properties not supplied, it will fall back to English. */
    paginationLocales: PropTypes.shape({
        itemsPerPage: PropTypes.string,
        goToPage: PropTypes.string,
        showing: PropTypes.string,
        of: PropTypes.string,
        ofMoreThan: PropTypes.string,
        page: PropTypes.string,
    }),
    /** The total items for the data grid pagination.
     *  Helpful when manual = true and more data can be fetched from the server so that pagination component displays the correct data
     */
    totalItems: PropTypes.number,
    /** Let the react-table know that data is server-side controlled by setting it to true*/
    manual: PropTypes.bool,
    /** To control the current page. Helpful when there is dynamic data. */
    page: PropTypes.number,
    /** Don't display anything related to amount of data in pagination component */
    simplifiedPagination: PropTypes.bool,
    /** The rows to be disabled. This only affects checkbox selection */
    disabledRows: PropTypes.arrayOf(PropTypes.string),
    /** The rows to display as loading (replacing the checkbox component) */
    loadingRows: PropTypes.arrayOf(PropTypes.string),
    /** The rows with status indicator. */
    statusRows: PropTypes.arrayOf(
        PropTypes.shape({
            /** The column ID whose data is used as the unique row ID */
            keyField: PropTypes.string.isRequired,
            /** The column field value whose data is used to verify the associated row */
            value: PropTypes.string.isRequired,
            /** Status type that will affect the display of status indicator */
            status: PropTypes.oneOf(Object.keys(STATUSES)),
        })
    ),
};

export default styled(DataGrid)`
    ${styles}
`;
